import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';

export const subscriptionApi = createApi({
  reducerPath: 'subscription',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSubscription: builder.query({
      query: () => ({ url: '/api/v3/subscriptions/1', method: 'GET' }),
    }),
    getOverdueUnpaidInvoiceExist: builder.query({
      query: () => ({ url: '/api/v3/subscriptions/overdue_invoice_exists', method: 'GET' }),
    }),
    // createPost: builder.mutation({
    //   query: (newPost) => ({
    //     url: '/posts',
    //     method: 'POST',
    //     data: newPost,
    //   }),
    // }),
  }),
});

export const { useGetSubscriptionQuery, useGetOverdueUnpaidInvoiceExistQuery } = subscriptionApi;
