import { getFilterCustomerId, getFormattedPaginationFields } from 'appRedux/utility';
import { getId } from 'appRedux/selectors/utility';

export const getFormattedQueryParamsForProjectItems = (filters) => ({
  page: filters.page,
  per_page: filters.perPage || 10,
  start_date: String(filters.startDate),
  end_date: String(filters.endDate),
  customer_id: getFilterCustomerId(filters),
  customer_type: filters.customerType === 'view_all' ? undefined : filters.customerType,
  visma_item_id: filters.vismaNumber?.vismaId,
});

export const getFormattedProjectItemsData = (response) => ({
  records: response.records.map((item) => ({ ...item, keyId: getId(5) })),
  ...getFormattedPaginationFields(response),
});

export const getFormattedItemProjectsData = (response) => ({
  records: response.records,
  ...getFormattedPaginationFields(response),
});

export const getFormattedUpdateItemsRequestBody = (data) => ({
  start_date: data.startDate,
  end_date: data.endDate,
  date: data.date,
  title: data.title,
  price: {
    per_unit: data.price.perUnit ? Number(data.price.perUnit) : undefined,
    unit: data.price.unit ? data.price.unit : undefined,
    vat: data.price.vat ? Number(data.price.vat) : undefined,
    title: data.price.title,
    account_number: data.price.accountNumber ? Number(data.price.accountNumber) : undefined,
    visma_item_id: data.price.vismaId,
  },
});
