import {
  getFormattedPaginationFields,
} from 'appRedux/selectors/utility';

export const getFormattedInvoiceHistoryResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: response.records || [],
});

export const formatRenewalCostEstimation = (data) => ({
  ...data,
  // eslint-disable-next-line max-len
  totalCostEstimation: Number(data.bankidSignCost) + Number(data.extraSeatsCost) + Number(data.fixedCost) + Number(data.smsCost),
});

export const getFormattedContractDetailsRequestBody = (details = {}) => ({
  name: details.name,
  identification_num: details.identificationNum,
  email: details.email,
  address: details.address,
  zip_code: details.zipCode,
  area: details.area,
  contact_person: details.contactPerson,
  mobile: details.mobile,
});

export const sortSubscriptionTiers = (data) => data.sort((a, b) => a.level - b.level);
