import { createAsyncThunk } from '@reduxjs/toolkit';
import snakify from 'utils/snakify';
import {
  setSnackbarError,
  setFilters,
  setCustomersFields,
  setSnackbarSuccess,
} from 'appRedux/actions';
import {
  getFormattedListData,
  handleFortnoxError,
  handleVismaError,
  getFormattedPaginationFields,
} from '../../utility';
import {
  getFormattedPendingInvoiceRecords,
  getFormattedPendingInvoicesOrders,
  pendingInvoicesFilters,
} from './selector';

export const requestPendingCustomerInvoices = createAsyncThunk(
  'invoices/fetchInvoicesToCreate',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { company } = getState().profile;
      const params = pendingInvoicesFilters(extra.getState(name, getState).filters);
      const response = await extra.axios.get('/api/v3/invoices', { params });
      const { records, ...rest } = response.data.data;
      dispatch(setFilters({ ...getFormattedPaginationFields(rest), name }));
      return { records: getFormattedPendingInvoiceRecords(records, company) };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_fetch_records_error'));
    }
  },
);

export const requestCustomerInvoiceOrders = createAsyncThunk(
  'invoices/fetchInvoicesOrdersToCreate',
  async (details, { extra, dispatch, getState }) => {
    try {
      const filters = pendingInvoicesFilters(
        { ...extra.getState(details.name, getState).filters, ...details.subtableFilters },
      );
      const params = { ...filters, customer_id: details.customerId };
      const response = await extra.axios.get('/api/v3/invoices/orders', { params });
      const records = getFormattedPendingInvoicesOrders(response.data.data.records);
      return { customerId: details.customerId, records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_fetch_orders_error'));
    }
  },
);

export const requestCreateInvoices = createAsyncThunk(
  'invoices/createInvoices',
  async (details, { extra, dispatch }) => {
    try {
      await extra.axios.post('/api/v3/invoices', snakify(details));
      dispatch(setCustomersFields({ refreshAllCustomers: {} }));
      dispatch(setSnackbarSuccess('snackbar_invoice_create_in_progress'));
    } catch (err) {
      handleFortnoxError(err);
      handleVismaError(err);
      dispatch(setSnackbarError('snackbar_invoice_create_orders_error'));
    }
  },
);

export const requestOrderInvoices = createAsyncThunk(
  'invoices/fetchCreatedInvoices',
  async (name, { extra, dispatch, getState }) => {
    try {
      const params = pendingInvoicesFilters(extra.getState(name, getState).filters);
      const response = await extra.axios.get('/api/v3/invoices/history', { params });
      const { records, ...rest } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...rest, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_fetch_invoice_history_error'));
    }
  },
);
