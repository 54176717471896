export const appRoutes = {
  appUpdates: '/app-updates',
  trialExpired: '/contract/trial-expired',
  signContract: '/contract/sign',
  companyDeactivated: '/contract/deactivated',
  signedContractWelcome: '/contract/welcome',
  companyNonOperational: '/contract/non-operational',
  companyInvoiceOverdue: '/contract/invoice-overdue',
  selectPlan: '/contract/select-plan',
  planFeatures: '/contract/plan-features',
  userCompanies: '/companies',

  eboka: '/eboka',
  ebokaBookings: '/eboka/bookings',
  ebokaIntegrationDetails: '/eboka/integration-details',

  fortnoxLanding: '/fortnox',
  selectLogin: '/select-login',
  login: '/login',
  register: '/register',
  proposalPublic: '/proposal/:token',
  proposalVerifyPdf: '/proposal/verify',
  companyDetails: '/company-details',
  forgotPassword: '/forgot-password',
  changePassword: '/change-password',
  passwordReset: '/reset-password',
  emailVerification: '/email-verification',
  // components: '/components',
  home: '/',
  messages: '/messages',
  calendar: '/calendar',
  notifications: '/notifications',
  invoices: '/2.0/invoices',
  reports: '/2.0/reports',
  bookings: '/bookings',
  orders: '/orders',
  projects: '/projects',
  customers: '/customers',
  invoicesNext: '/invoices',
  reportsNext: '/reports',
  userDivisions: '/users/divisions',
  keys: '/keys',
  skills: '/skills',
  settings: '/settings',
  profile: '/profile',
  activeSessionDetails: '/active-session-details',
  pushNotificationSettings: '/settings/push-notifications',
  timeWorkCodes: '/settings/time-codes',
  overtime: '/settings/overtime',
  services: '/services',
  items: '/items',
  requests: '/requests',
  timeManagement: '/2.0/timeManagement',
  timeManagementNext: '/timeManagement',
  history: '/history',
  users: '/users',
  usersLogs: '/users/logs/:companyId',
  weeklyWorkPlans: '/weekly-work-plans',
  proposals: '/proposals',
  bookingProjectMigration: '/booking-project-migration',
  productUpdates: '/product-updates',
  billing: '/billing',
  planDetails: '/plan-details',

  superAdminCompanySettings: '/admin/company-settings',
  superAdminCompanies: '/admin/companies',
  superAdminCompany: '/admin/companies/:id',
  superAdminInvoices: '/admin/invoices',
  superAdminErrors: '/admin/errors',
  superAdminUsers: '/admin/users',
  superAdminPermissions: '/admin/permissions',
  superAdminMobileLoginHistory: '/admin/mobile-logins',
  superAdminRedDays: '/admin/red-days',
  superAdminSMS: '/admin/sms-signature',
  superAdminCustomers: '/admin/customers',
  superAdminItems: '/admin/items',
  superAdminAppVersion: '/admin/super-admin-app-version',
  superAdminDatabaseBackup: '/admin/database/backups',
  superAdminStorage: '/admin/storage',
  superAdminSettings: '/admin/settings',
  superAdminBookingIssues: '/admin/booking-issues',
  superAdminOpenApiIntegration: '/admin/open-api-integration',
  superAdminHistory: '/admin/history',
  superAdminProductUpdates: '/admin/product-updates',
  superAdminSubscriptionTiers: '/admin/subscription-tiers',

  employeeCompanies: '/employee/companies',
  employeeSchedule: '/employee/schedule',
  employeeOpenShifts: '/employee/open-shifts',
  employeeOpenShiftDetail: '/employee/open-shift-detail',
  employeeProjects: '/employee/projects',
  employeeProjectDetail: '/employee/projects/:projectId',
  employeeRequests: '/employee/request',
  employeeRequest: '/employee/request/:requestId',
  employeeScheduleDetail: '/employee/schedule/:scheduleId',
  employeeCustomerLogs: '/employee/schedule/:scheduleId/logs/:customerId',
  employeePunch: '/employee/punch',
  employeeOverTime: '/employee/overtime',
  employeeTimeSheet: '/employee/timesheet',
  employeeScheduleChecklist: '/employee/schedule/:scheduleId/checklists',
  employeeScheduleKeys: '/employee/schedule/:scheduleId/keys',
  employeeProfile: '/employee/profile',
  employeeActiveSessionDetails: '/employee/active-session-details',
  employeeOrderDetail: '/employee/order',
  employeeOrderCustomerLogs: '/employee/order/logs',
  employeeOrderKeys: '/employee/order/keys',
  employeeOrderChecklist: '/employee/order/checklists',
  employeeOrderItems: '/employee/order/items',
  employeeMessages: '/employee/message',
  sms: '/sms',
  checklists: '/checklists',
  bookingSmsTemplate: '/booking-sms-template',
  proposalSmsTemplate: '/proposal-sms-template',
  apiKeyList: '/api-key-list',
  orderLocationMap: '/map',
};
