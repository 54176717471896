import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestOrdersSummary,
  requestTop5CustomersByRevenue,
  requestCustomerOrganisationRevenueSummary,
  requestCustomerOrganisationProposalsSummary,
  requestAnnualOrdersSummaryCounts,
  requestProposalsSummary,
  requestTop5Users,
  requestAnnualUsersReports,
  requestUsersForAvatars,
  requestAnnualRevenueReports,
  requestAnnualTotalHoursPercent,
} from './thunk';

const annualRecap = createSlice({
  name: 'annualRecap',
  initialState: {
    showAnnualRecapDialog: false,
    topUsers: [],
    topCustomers: [],
    recurringOrderTimePercentage: 0,
    singleOrderTimePercentage: 0,
    totalSingleOrders: 0,
    totalRecurringOrders: 0,
    completedOrdersCount: 0,
    cancelledOrdersCount: 0,
    activeOrdersCount: 0,
    totalOrganizationOrders: 0,
    totalNonOrganizationOrders: 0,
    winRate: 0,
    previousWinRate: 0,
    totalProposalsCount: 0,
    organisationProposalsCount: 0,
    nonOrganisationProposalsCount: 0,
    signedBySignatureProposalsCount: 0,
    signedByNameProposalsCount: 0,
    totalOrdersRevenue: 0,
    previousTotalOrdersRevenue: 0,
    recurringOrdersRevenue: 0,
    previousRecurringOrdersRevenue: 0,
    increasePerMonth: 0,
    organizationRevenuePrecentage: 0,
    nonOrganizationRevenuePrecentage: 0,
    usersCreatedIn2024: 0,
    usersCreatedIn2023: 0,
    users: [],
    activeUsers: 0,
    totalOrdersDuration: 0,
  },
  reducers: {
    setAnnualRecapFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(requestTop5CustomersByRevenue.fulfilled,
        requestCustomerOrganisationRevenueSummary.fulfilled,
        requestOrdersSummary.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
        fsip: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestTop5Users.fulfilled,
        requestAnnualOrdersSummaryCounts.fulfilled, requestProposalsSummary.fulfilled,
        requestCustomerOrganisationProposalsSummary.fulfilled, requestAnnualUsersReports.fulfilled,
        requestUsersForAvatars.fulfilled, requestAnnualRevenueReports.fulfilled,
        requestAnnualTotalHoursPercent.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
      }),
    );
  },
});

const { reducer, actions } = annualRecap;

export const { setAnnualRecapFields } = actions;

export default reducer;
