import React from 'react';
import axiosNext from 'utils/axios';
import userAxios from 'utils/userAxios';
import { setSnackbarError, setSnackbarSuccess, setAuthFields } from 'appRedux/actions';
import { requestProfile } from 'appRedux/owner/profile/thunks';
import { alert, Box, Typography } from 'components/library';
import { fm } from 'utils/string';
import { requestProfile as requestEmployeeProfile } from 'appRedux/employee/profile/thunk';
import { get } from 'utils/lodash';
import { userRoles, thirdPartyProviders } from 'utils/enum';

export const requestSignInWithProvider = (idToken, provider, history) => async (dispatch) => {
  try {
    const body = {
      user_login_detail: {
        id_token: idToken,
        provider,
      },
    };
    const response = await axiosNext.post('/api/v3/user_login_details/omniauth_callback/signin', body);
    const { user } = response.data.data;
    userAxios.defaults.headers.Authorization = `Bearer ${user.token}`;
    dispatch(setAuthFields({
      userToken: user.token,
    }));
    localStorage.setItem('user_token', user.token);
    history.push('/companies');
  } catch (err) {
    if (get('response.data.errors[0].details.code')(err)) {
      alert(
        <Box>
          <Typography>{fm('1012', undefined, { provider })}</Typography>
          <Typography>{fm('go_to_setting_google_account', undefined, { provider })}</Typography>
        </Box>,
        { title: provider === thirdPartyProviders.google
          ? fm('google_account', 'Google account') : fm('apple_account', 'Apple account') },
      );
    }
    dispatch(setSnackbarError(provider === thirdPartyProviders.google
      ? 'snackbar_google_signin_error' : 'snackbar_apple_signin_error'));
  }
};

export const requestAttachProviderAccount = (idToken, provider) => async (dispatch, getState) => {
  try {
    const { auth: { role } } = getState();
    const body = {
      user_login_detail: {
        id_token: idToken,
        provider,
      },
    };
    await axiosNext.post('/api/v3/user_login_details/omniauth_callback/attach', body);
    if (role === userRoles.employee) {
      dispatch(requestEmployeeProfile());
    } else {
      dispatch(requestProfile());
    }
    dispatch(setSnackbarSuccess(provider === thirdPartyProviders.google
      ? 'snackbar_google_account_attached' : 'snackbar_apple_account_attached'));
  } catch (err) {
    if (get('response.data.errors[0].details.code')(err)) {
      const errorCode = get('response.data.errors[0].details.code')(err);
      return dispatch(setSnackbarError(errorCode));
    }
    dispatch(setSnackbarError(provider === thirdPartyProviders.google
      ? 'snackbar_google_attach_error' : 'snackbar_apple_attach_error'));
  }
};

export const requestRemoveProviderAccount = (provider) => async (dispatch, getState) => {
  try {
    const { auth: { role } } = getState();
    const body = {
      user_login_detail: {
        provider,
      },
    };
    await axiosNext.post('/api/v3/user_login_details/omniauth_callback/detach', body);
    dispatch(setSnackbarSuccess(provider === thirdPartyProviders.google
      ? 'snackbar_google_account_removed' : 'snackbar_apple_account_removed'));
    if (role === userRoles.employee) {
      dispatch(requestEmployeeProfile());
    } else {
      dispatch(requestProfile());
    }
  } catch (err) {
    dispatch(setSnackbarSuccess(provider === thirdPartyProviders.google
      ? 'snackbar_google_remove_error' : 'snackbar_apple_remove_error'));
  }
};
