import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Footer, Box, Button, confirm } from 'components/library';
import { organizationSettingTypes } from 'utils/enum';
import { get } from 'utils/lodash';
import { fm } from 'utils/string';
import { proposalStatusTypes } from 'components/Proposal/utility';
import BankIdDialog from 'views/Proposal/CustomerProposal/ProposalDetails/BankIdDialog';
import { getStandardDate } from 'utils/dateTime';
import AcceptProposalDialog from './AcceptProposalDialog';
import ProposalSignedMessage from './ProposalSigned';
import DeclineOfferDialog from './DeclineOfferDialog';

const ProposalFooter = (props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDeclineOfferDialog, setShowDeclineOfferDialog] = useState(false);
  const { proposal, company } = props;

  const requiresBankIdSign = useMemo(() => {
    const companySettings = get('companyDetails.companySettings')(company);
    if (!companySettings) return {};
    const proposalBankidSignSetting = companySettings.find(
      (d) => d.settingCode === organizationSettingTypes.proposalBankIdSignature,
    );
    return proposalBankidSignSetting.enable && proposal.requiresBankidSign;
  }, [company.companySettings]);

  const onClickAccept = () => {
    setShowConfirmDialog(true);
  };

  const onClickDeclineOffer = () => {
    setShowDeclineOfferDialog(true);
  };

  const onClickAcceptDenyOffer = async ({ status, signature }) => {
    const url = window.location.href;
    const token = url.split('/').pop();
    if (await confirm(fm('proposal_approve_confirm_msg'), { title: fm('approve_proposal') })) {
      props.requestUpdateProposalFromCustomer({ status, token, signature });
    }
  };

  if (proposal.proposalSignature && !proposal.proposalSignature.staled && requiresBankIdSign) {
    return (
      <Footer fullWidth>
        <BankIdDialog proposal={proposal} />
      </Footer>
    );
  }

  if (new Date(getStandardDate()) > new Date(proposal.expiryDate)) return <Footer fullWidth />;

  return (
    <Footer fullWidth>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        {(proposal.status === proposalStatusTypes.signed || proposal.status === proposalStatusTypes.cancelled)
          ? (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%">
              {proposal.status === proposalStatusTypes.signed && (
                <ProposalSignedMessage signeeName={proposal.customerName} />
              )}
              {proposal.status === proposalStatusTypes.cancelled && (
                <Typography>{fm('proposal_deny')}</Typography>
              )}
            </Box>
          ) : (
            <Box display="flex">
              <Button
                size="medium"
                variant="text"
                onClick={onClickDeclineOffer}
              >
                {fm('decline')}
              </Button>
              {requiresBankIdSign && <BankIdDialog proposal={proposal} />}
              {!requiresBankIdSign && (
                <Button
                  size="medium"
                  onClick={onClickAccept}
                  variant="contained"
                  color="primary"
                >
                  {fm('accept_offer')}
                </Button>
              )}
            </Box>
          )}
      </Box>
      <AcceptProposalDialog
        onClickAcceptDenyOffer={onClickAcceptDenyOffer}
        open={showConfirmDialog}
        setOpen={setShowConfirmDialog}
      />
      <DeclineOfferDialog
        open={showDeclineOfferDialog}
        setOpen={setShowDeclineOfferDialog}
        proposalPolicies={company.companyPolicies}
        requestUpdateProposalFromCustomer={props.requestUpdateProposalFromCustomer}
      />
    </Footer>
  );
};

export default ProposalFooter;

ProposalFooter.propTypes = {
  requestUpdateProposalFromCustomer: PropTypes.func.isRequired,
  company: PropTypes.shape({
    companySettings: PropTypes.arrayOf(PropTypes.shape({})),
    companyPolicies: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  proposal: PropTypes.shape({
    requiresBankidSign: PropTypes.bool,
    status: PropTypes.string,
    expiryDate: PropTypes.string,
    customerName: PropTypes.string,
    proposalSignature: PropTypes.shape({
      staled: PropTypes.bool,
    }),
  }),
};
