import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { fm } from 'utils/string';
import { MoreVertIcon } from 'components/library/icons';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { IconButton, List, ListItem, ListItemButton } from '@mui/material';

export function OrderOptions (props) {
  const { onChangeOrderValue, options } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (value) => {
    onChangeOrderValue(value);
    setAnchorEl(null);
  };

  const [anchorOrigin, transformOrigin] = useMemo(() => [
    { vertical: 'bottom', horizontal: 'center' },
    { vertical: 'top', horizontal: 'center' },
  ], []);

  return (
    <>
      <IconButton>
        <MoreVertIcon
          fontSize="small"
          onClick={(e) => setAnchorEl(e.target)}
          className="pointer"
        />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Grid container justifyContent="center" direction="column">
          <Grid item>
            <Box m={1} textAlign="center">
              <Typography variant="subtitle1">
                {fm('sort_by', 'Sort by')}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item>
            <List disablePadding>
              {options.map((option) => (
                <ListItem key={option.value} onClick={() => handleClick(option.value)} disableGutters disablePadding>
                  <ListItemButton>{option.label}</ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

OrderOptions.propTypes = {
  onChangeOrderValue: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};
