import { setSnackbarError, setFilters, setSnackbarSuccess } from 'appRedux/actions';
import FileSaver from 'file-saver';
import { deepTrim } from 'utils/string';
import { appRoutes } from 'utils/enum';
import { get } from 'utils/lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { invoiceStatus, paidInvoicesFilter } from 'components/Billing/utility';
import { requestProfile } from '../profile/thunks';
import {
  getFormattedInvoiceHistoryResponse,
  formatRenewalCostEstimation,
  getFormattedContractDetailsRequestBody,
  sortSubscriptionTiers,
} from './selector';

export const requestUsers = createAsyncThunk(
  'billing/getSeats',
  async (_, { extra, dispatch }) => {
    try {
      const params = {
        is_active: true,
      };
      const response = await extra.axios.get('/api/v1/users', { params });
      return { records: response.data.data.records || [] };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.users.gettingUsers.error'));
    }
  },
);

export const getActiveSubscription = createAsyncThunk(
  'billing/getActiveSubscription',
  async (_, { extra, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/subscriptions/1');
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const requestDownloadInvoicePdf = createAsyncThunk(
  'billing/getInvoicePdf',
  async (invoiceHistoryId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/subscription_invoice_histories/${invoiceHistoryId}/invoice_pdf`);
      fetch(`data:application/pdf;base64,${response.data.data.pdfFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, response.data.data.pdfFile.name));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_download_pdf_error'));
    }
  },
);

export const requestPaidInvoiceHistories = createAsyncThunk(
  'billing/fetchBillingPaidInvoiceHistories',
  async (name, { extra, dispatch, getState, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        page: filters.page || 1,
        per_page: filters.perPage || 10,
        status: invoiceStatus.paid,
        ...(filters.filterBy === paidInvoicesFilter.before2025 && ({
          end_date: '2024-12-31',
        })),
      };
      const response = await extra.axios.get('/api/v3/subscription_invoice_histories', { params });
      const { records, ...nextFilters } = getFormattedInvoiceHistoryResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records, isBefore: filters.filterBy === paidInvoicesFilter.before2025 };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUnpaidInvoiceHistories = createAsyncThunk(
  'billing/fetchBillingUnpaidInvoiceHistories',
  async (_, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        page: 1,
        per_page: 30,
        status: invoiceStatus.pending,
      };
      const response = await extra.axios.get('/api/v3/subscription_invoice_histories', { params });
      const { records } = getFormattedInvoiceHistoryResponse(response.data.data);
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestPaymentLinkForInvoice = createAsyncThunk(
  'billing/getBillPaymentLink',
  async (invoiceHistoryId, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = {
        auto_pay: false,
        invoice_history_id: invoiceHistoryId,
      };
      const response = await extra.axios.post('/api/v3/stripe/payments/create_checkout_session', body);
      const paymentUrl = response.data.url;
      if (paymentUrl) {
        window.location.replace(paymentUrl);
      }
    } catch (err) {
      const errorCode = get('response.data.errors[0].details.code')(err);
      if (errorCode === '20001') {
        dispatch(setSnackbarError(errorCode));
        setTimeout(() => {
          dispatch(requestUnpaidInvoiceHistories());
        }, 1000);
      }
      return rejectWithValue(err);
    }
  },
);

export const requestGetSubscriptionTiers = createAsyncThunk(
  'billing/getSubscriptionTiers',
  async (_, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/subscription_tiers');
      return sortSubscriptionTiers(response.data.data.subscriptionTiers);
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpgradeSubscriptionTier = createAsyncThunk(
  'billing/updateSubscriptionTier',
  async (subscriptionTierId, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = {
        subscription_tier_id: subscriptionTierId,
      };
      await extra.axios.put('/api/v3/subscriptions/upgrade', body);
      dispatch(getActiveSubscription());
      dispatch(setSnackbarSuccess('snackbar_upgrade_subscription_tier_success'));
    } catch (err) {
      if (err.response.data.errors[0].status === 422) {
        return rejectWithValue({ subscriptionTierId, error: 'pending_invoice_present' });
      }
      return rejectWithValue(err);
    }
  },
);

export const requestDowngradeSubscriptionTier = createAsyncThunk(
  'billing/updateSubscriptionTier',
  async (subscriptionTierId, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = {
        subscription_tier_id: subscriptionTierId,
      };
      await extra.axios.put('/api/v3/subscription_autorenewals/downgrade', body);
      dispatch(getActiveSubscription());
      dispatch(setSnackbarSuccess('snackbar_downgrade_subscription_tier_success'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_invoice_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestSmsBillingHistories = createAsyncThunk(
  'billing/requestSmsBillingHistory',
  async (name, { extra, dispatch, getState, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        page: filters.page || 1,
        per_page: filters.perPage || 10,
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/subscriptions/sms_histories', { params });
      const { records, ...nextFilters } = getFormattedInvoiceHistoryResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_sms_billing_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestBankIdBillingHistories = createAsyncThunk(
  'billing/requestBankIdBillingHistory',
  async (name, { extra, dispatch, getState, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        page: filters.page || 1,
        per_page: filters.perPage || 10,
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/subscriptions/bankid_sign_histories', { params });
      const { records, ...nextFilters } = getFormattedInvoiceHistoryResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_bankId_billing_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestSeatBillingHistories = createAsyncThunk(
  'billing/requestSeatBillingHistories',
  async (name, { extra, dispatch, getState, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        page: filters.page || 1,
        per_page: filters.perPage || 10,
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/subscriptions/seat_histories', { params });
      const { records, ...nextFilters } = getFormattedInvoiceHistoryResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_seat_billing_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestgetRenewalCostEstimation = createAsyncThunk(
  'billing/requestgetRenewalCostEstimation',
  async (_, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/subscriptions/renewal_cost_estimation');
      return formatRenewalCostEstimation(response.data.data);
    } catch (err) {
      dispatch(setSnackbarError('snackbar_seat_billing_history_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCancelSubscription = createAsyncThunk(
  'billing/requestCancelSubscription',
  async (reason, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = {
        cancellation_reason: reason,
      };
      await extra.axios.put('/api/v3/subscription_autorenewals/cancel', body);
      dispatch(getActiveSubscription());
      dispatch(setSnackbarSuccess('snackbar_cancel_subscription_success'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_subscription_cancel_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestContinueSubscription = createAsyncThunk(
  'billing/requestContinueSubscription',
  async (_, { extra, dispatch, rejectWithValue }) => {
    try {
      await extra.axios.put('/api/v3/subscription_autorenewals/continue_subscription');
      dispatch(getActiveSubscription());
      dispatch(requestgetRenewalCostEstimation());
      dispatch(setSnackbarSuccess('snackbar_continue_subscription_success'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_subscription_continue_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestChangeBillingCycle = createAsyncThunk(
  'billing/requestChangeBillingCycle',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = {
        billing_cycle: data.billingCycle,
      };
      await extra.axios.put('/api/v3/subscription_autorenewals/change_billing_cycle', body);
      dispatch(getActiveSubscription());
      dispatch(requestgetRenewalCostEstimation());
      dispatch(setSnackbarSuccess('snackbar_change_billing_cycle_success'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_subscription_continue_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestSubmitContract = createAsyncThunk(
  'billing/requestSubmitContract',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const companyDetails = deepTrim(getFormattedContractDetailsRequestBody(data));
      const body = {
        subscription: {
          subscription_tier_id: data.planId,
          billing_cycle: data.billingCycle,
        },
        company: companyDetails,
      };
      await extra.axios.post('/api/v3/subscriptions', body);
      dispatch(requestProfile());
      dispatch(getActiveSubscription());
      data.history.push(appRoutes.signedContractWelcome);
    } catch (err) {
      const errorInfo = err.response.data.errors[0].details.ErrorInformation;
      if (errorInfo) {
        dispatch(setSnackbarError(errorInfo.message));
      }
      return rejectWithValue(err);
    }
  },
);

export const requestGetInvoiceDetails = createAsyncThunk(
  'billing/requestGetInvoiceDetails',
  async (_, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/subscriptions/invoice_details');
      return response.data.data;
    } catch (err) {
      const errorInfo = err.response.data.errors[0].details.ErrorInformation;
      if (errorInfo.Code === '3003') {
        dispatch(setSnackbarError('snackbar_fornox_integration_expired'));
      } else {
        dispatch(setSnackbarError(errorInfo.Message));
      }
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateInvoiceDetails = createAsyncThunk(
  'billing/requestUpdateInvoiceDetails',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = {
        invoice_details: {
          email: data.email,
          address: data.address,
          area: data.area,
          zip_code: data.zipCode,
          delivery_type: data.invoiceDeliveryMethod,
        },
      };
      const response = await extra.axios.put('/api/v3/subscriptions/update_invoice_details', body);
      dispatch(setSnackbarSuccess('snackbar_update_invoice_details_success'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_update_invoice_details_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestGetCostEstimation = createAsyncThunk(
  'billing/requestGetCostEstimation',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        subscription_tier_id: data.subscriptionTierId,
        billing_cycle: data.billingCycle,
        current_seats: data.currentSeats,
      };
      const response = await extra.axios.get('/api/v3/subscriptions/cost_estimation', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_update_invoice_details_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUploadPaymentReceipt = createAsyncThunk(
  'billing/requestUploadPaymentReceipt',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('payment_document', data.file);
      formData.append('payment_date', data.paymentDate);
      const response = await extra.axios.put(
        `/api/v3/subscription_invoice_histories/${data.id}/mark_invoice_as_paid`,
        formData,
      );
      dispatch(setSnackbarSuccess('snackbar_upload_payment_receipt_success'));
      dispatch(requestProfile());
      dispatch(requestUnpaidInvoiceHistories());
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_upload_payment_receipt_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestDownloadStripePaymentReceiptPdf = createAsyncThunk(
  'billing/getStripePaymentReceiptPdf',
  async (invoiceHistoryId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(
        `/api/v3/subscription_invoice_histories/${invoiceHistoryId}/stripe_payment_receipt_pdf`,
      );
      fetch(`data:application/pdf;base64,${response.data.data.pdfFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, response.data.data.pdfFile.name));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_stripe_payment_receipt_download_pdf_error'));
    }
  },
);
