import { configureStore } from '@reduxjs/toolkit';
import { multiStateGet } from 'appRedux/reducers/utility';
import axios from 'axios';
import get from 'lodash/fp/get';
import urls from 'utils/urls';
import camelize from 'camelize';
import { setLoading, SIGNOUT_USER_SUCCESS } from 'appRedux/actions';
import * as Sentry from '@sentry/react';
import rootReducer from 'appRedux/reducers/index';
import { subscriptionApi } from 'appRedux/owner/billing/subscriptionApi';
import { superAdminCompanyApi } from 'appRedux/superUser/company/companyApi';
import { applicationApi } from 'appRedux/owner/application/applicationApi';
import { customerApi } from 'appRedux/owner/customers/customerApi';

const getStateWithFilters = (name, getState) => {
  const state = getState();
  const filters = multiStateGet(name, state.filters);
  return { ...state, filters };
};

const getHeadersForSubCompany = (companyId, getState) => {
  const state = getState();
  const { company } = state.profile;

  if (companyId === company.id || !companyId) return;

  const { subCompany = [] } = state.companyIntegration.integrations;
  const { companyToken } = subCompany.find((company) => company.companyId === companyId);
  const headers = {
    Authorization: `Bearer  ${companyToken}`,
    'Content-Type': 'application/json',
    'X-User-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return headers;
};

let apiRequestsPending = 0;

export const storeAxios = axios.create({

  baseURL: urls.getApiBaseUrl(),

  headers: {
    Authorization: `Bearer  ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
    'X-User-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'ngrok-skip-browser-warning': true,
  },
});

export const userSignOut = () => (dispatch) => {
  const userWasLoggedIn = Boolean(localStorage.getItem('token'));
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  dispatch({ type: SIGNOUT_USER_SUCCESS });
  if (userWasLoggedIn) window.location.reload();
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: { axios: storeAxios, getState: getStateWithFilters, getHeadersForSubCompany },
    },
    serializableCheck: false,
  }).concat(subscriptionApi.middleware)
    .concat(superAdminCompanyApi.middleware)
    .concat(customerApi.middleware)
    .concat(applicationApi.middleware),
});

function onError (error) {
  apiRequestsPending -= 1;
  if (apiRequestsPending === 0) {
    store.dispatch(setLoading(false));
  }

  if (get('response.status')(error) === 401) {
    store.dispatch(userSignOut());
  } else {
    Sentry.captureException(error, { extra: get('response.data')(error) });
  }
  return Promise.reject(error);
}

storeAxios.interceptors.request.use((config) => {
  store.dispatch(setLoading(true));
  apiRequestsPending += 1;
  return config;
}, onError);

// Add a response interceptor
storeAxios.interceptors.response.use((response) => {
  apiRequestsPending -= 1;
  if (apiRequestsPending === 0) {
    store.dispatch(setLoading(false));
  }
  response.data.data = camelize(response.data.data);
  return response;
}, onError);

export default store;
