import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';

export const TableCell = styled(
  MuiTableCell,
  { shouldForwardProp: (prop) => !['minWidth', 'textAlign'].includes(prop) },
)(({ minWidth, textAlign = 'center' }) => ({
  minWidth,
  textAlign,
}));

export const TableRow = styled(MuiTableRow)(({ theme, onClick }) => ({
  ...(onClick && {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transition: 'background-color 0.3s ease',
      cursor: 'pointer',
    },
  }),
}));

export const SubtableHead = styled(MuiTableHead)({
  background: '#dbdbdb',
});

export const SubtableBody = styled(MuiTableBody)({
  background: '#f5f5f5',
});
