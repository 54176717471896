import { createSlice } from '@reduxjs/toolkit';
import { requestProjectItems, requestItemProjects, requestUpdateProjectItems } from './thunk';

const projectItems = createSlice({
  name: 'projectItems',
  initialState: {
    records: [],
    itemsTitleProjectsMap: {},
    expandedRowsIndexes: {},
    selectedItemTitle: '',
    refreshAllProjectItems: {},
    projectItemsSubTablePaginationMap: {},
  },
  reducers: {
    setProjectItemsFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestProjectItems.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      expandedRowsIndexes: {},
    }));
    builder.addCase(requestItemProjects.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestUpdateProjectItems.fulfilled, (state) => ({
      ...state,
      fsip: false,
      refreshAllProjectItems: {},
      showConfirmUpdateProjectItemsDialog: false,
      selectedItemTitle: null,
    }));
  },
});

const { reducer, actions } = projectItems;

export const { setProjectItemsFields } = actions;

export default reducer;
