import { fm } from 'utils/string';
import { colors } from 'utils/colors';

export const billingHistoryTypes = {
  seats: 'seats',
  sms: 'sms',
  bankId: 'bankId',
};

export const billingHistoryTypesNames = [
  { label: fm('seats'), value: billingHistoryTypes.seats },
  { label: fm('sms'), value: billingHistoryTypes.sms },
  { label: fm('bankId'), value: billingHistoryTypes.bankId },
];

export const billingCycleTypes = {
  monthly: 'monthly',
  quarterly: 'quarterly',
};

export const invoiceStatus = {
  pending: 'pending',
  paid: 'paid',
};

export const subscriptionPlanColors = {
  1: colors.lightCyan,
  2: colors.lightGray,
  3: colors.lightYellow,
};

export const subscriptionTierMoto = {
  1: fm('subscription_tier_moto_level1'),
  2: fm('subscription_tier_moto_level2'),
  3: fm('subscription_tier_moto_level3'),
};

export const paidInvoicesFilter = {
  before2025: 'before',
  from2025: '2025',
};

export const invoiceDeliveryMethods = {
  email: 'EMAIL',
  eInvoice: 'ELECTRONICINVOICE',
};
