export const FETCH_ERROR = 'fetch_error';
export const INIT_URL = 'INIT_URL';
export const RESET_APPLICATION_UI_STATE = 'RESET_APPLICATION_UI_STATE';
export const RESET_AUTH_FIELDS = 'RESET_AUTH_FIELDS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';
export const SET_AREA_SUGGESTIONS = 'SET_AREA_SUGGESTIONS';
export const SET_CUSTOMER_AREA_SUGGESTIONS = 'SET_CUSTOMER_AREA_SUGGESTIONS';
export const SET_SKILLS_SUGGESTIONS = 'SET_SKILLS_SUGGESTIONS';
export const SET_APPLICATION_FIELDS = 'SET_APPLICATION_FIELDS';
export const SET_APPLICATION_UI_STATE = 'SET_APPLICATION_UI_STATE';
export const SET_AUTH_FIELDS = 'SET_AUTH_FIELDS';
export const SET_USER_FEEDBACK_FIELDS = 'SET_USER_FEEDBACK_FIELDS';
export const SET_AUTOCOMPLETE_FIELDS = 'SET_AUTOCOMPLETE_FIELDS';
export const SET_BIG_CALENDAR_FIELDS = 'SET_BIG_CALENDAR_FIELDS';
export const SET_BOOKING_SMS_HISTORY_FIELDS = 'SET_BOOKING_SMS_HISTORY_FIELDS';
export const SET_BOOKING_DOCUMENTS_FIELDS = 'SET_BOOKING_DOCUMENTS_FIELDS';
export const SET_BOOKINGS_CUSTOMER_REQUESTS = 'SET_BOOKINGS_CUSTOMER_REQUESTS';
export const SET_BOOKINGS_FIELDS = 'SET_BOOKINGS_FIELDS';
export const SET_BOOKINGS_INVOICE = 'SET_BOOKINGS_INVOICE';
export const SET_BOOKING_INVOICE_ID_FIELDS = 'SET_BOOKING_INVOICE_ID_FIELDS';
export const SET_BOOKINGS_ITEMS_FIELDS = 'SET_BOOKINGS_ITEMS_FIELDS';
export const SET_BOOKINGS_PDF_FIELDS = 'SET_BOOKINGS_PDF_FIELDS';
export const SET_BREAK_TIME_SETTING_FIELDS = 'SET_BREAK_TIME_SETTING_FIELDS';
export const SET_CALENDAR_FIELDS = 'SET_CALENDAR_FIELDS';
export const SET_CHECKLIST_COLLECTION_SUGGESTIONS = 'SET_CHECKLIST_COLLECTION_SUGGESTIONS';
export const SET_COLOR_FIELDS = 'SET_COLOR_FIELDS';
export const SET_COMPANY_BILLING_INFO_FIELDS = 'SET_COMPANY_BILLING_INFO_FIELDS';
export const SET_ORGANIZATION_SETTING_FIELDS = 'SET_ORGANIZATION_SETTING_FIELDS';
export const SET_CONFIRM_MODAL_FIELDS = 'SET_CONFIRM_MODAL_FIELDS';
export const SET_CONSTITUTION_FIELDS = 'SET_CONSTITUTION_FIELDS';
export const SET_CUSTOMER_DOCUMENTS_FIELDS = 'SET_CUSTOMER_DOCUMENTS_FIELDS';
export const SET_CUSTOMER_KEY_HISTORY_FIELDS = 'SET_CUSTOMER_KEY_HISTORY_FIELDS';
export const SET_CUSTOMER_REQUESTS_FIELDS = 'SET_CUSTOMER_REQUESTS_FIELDS';
export const SET_CUSTOMER_SUGGESTIONS = 'SET_CUSTOMER_SUGGESTIONS';
export const SET_CUSTOMER_ZIP_CODE_SUGGESTIONS = 'SET_CUSTOMER_ZIP_CODE_SUGGESTIONS';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMERS_FIELDS = 'SET_CUSTOMERS_FIELDS';
export const SET_CUSTOMER_BOOKING_FIELDS = 'SET_CUSTOMER_BOOKING_FIELDS';
export const SET_CUSTOMER_BOOKING_FILTERS = 'SET_CUSTOMER_BOOKING_FILTERS';
export const SET_CUSTOMER_RECURRING_BOOKING_FIELDS = 'SET_CUSTOMER_RECURRING_BOOKING_FIELDS';
export const SET_CUSTOMER_RECURRING_BOOKING_FILTERS = 'SET_CUSTOMER_RECURRING_BOOKING_FILTERS';
export const SET_CUSTOMER_INVOICE_HISTORY_FIELDS = 'SET_CUSTOMER_INVOICE_HISTORY_FIELDS';
export const SET_CUSTOMER_INVOICE_HISTORY_FILTERS = 'SET_CUSTOMER_INVOICE_HISTORY_FILTERS';
export const SET_DEPARTMENT_SUGGESTIONS = 'SET_DEPARTMENT_SUGGESTIONS';
export const SET_EMPLOYEE_AVAILABILITY_FIELDS = 'SET_EMPLOYEE_AVAILABILITY_FIELDS';
export const SET_PAGINATED_EMPLOYEE_AVAILABILITY_FIELDS = 'SET_PAGINATED_EMPLOYEE_AVAILABILITY_FIELDS';
export const SET_EMPLOYEE_DOCUMENTS_FIELDS = 'SET_EMPLOYEE_DOCUMENTS_FIELDS';
export const SET_EMPLOYEE_HOUR_REPORT_FIELDS = 'SET_EMPLOYEE_HOUR_REPORT_FIELDS';
export const SET_EMPLOYEE_SUGGESTIONS = 'SET_EMPLOYEE_SUGGESTIONS';
export const SET_EMPLOYEE_VIEW_TIMEMANAGEMENT_FIELDS = 'SET_EMPLOYEE_VIEW_TIMEMANAGEMENT_FIELDS';
export const SET_EMPLOYEE_VIEW_SHIFT_TIMES_FIELDS = 'SET_EMPLOYEE_VIEW_SHIFT_TIMES_FIELDS';
export const SET_EMPLOYEE_VIEW_OVERTIME_FIELDS = 'SET_EMPLOYEE_VIEW_OVERTIME_FIELDS';
export const SET_EXTENDABLE_RECURRING_BOOKINGS_FIELDS = 'SET_EXTENDABLE_RECURRING_BOOKINGS_FIELDS';
export const SET_EXTENDABLE_BOOKINGS_FIELDS = 'SET_EXTENDABLE_BOOKINGS_FIELDS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_FORTNOX_ACCOUNTS_FIELDS = 'SET_FORTNOX_ACCOUNTS_FIELDS';
export const SET_FORTNOX_ITEM_SUGGESTIONS = 'SET_FORTNOX_ITEM_SUGGESTIONS';
export const SET_USER_GROUPS_FIELDS = 'SET_USER_GROUPS_FIELDS';
export const SET_USER_GROUP_SUGGESTIONS = 'SET_USER_GROUP_SUGGESTIONS';
export const SET_HANDLED_RECURRING_BOOKINGS_FIELDS = 'SET_HANDLED_RECURRING_BOOKINGS_FIELDS';
export const SET_HISTORY_DATA = 'SET_HISTORY_DATA';
export const SET_TAGS_FIELDS = 'SET_TAGS_FIELDS';
export const SET_HISTORY_FIELDS = 'SET_HISTORY_FIELDS';
export const SET_INFO_MODAL_FIELDS = 'SET_INFO_MODAL_FIELDS';
export const SET_INITIAL_FILTERS = 'SET_INITIAL_FILTERS';
export const SET_INVOICES_FIELDS = 'SET_INVOICES_FIELDS';
export const SET_INVOICES_HISTORY_FIELDS = 'SET_INVOICES_HISTORY_FIELDS';
export const SET_ITEM_SUGGESTIONS = 'SET_ITEM_SUGGESTIONS';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_ITEMS_FIELDS = 'SET_ITEMS_FIELDS';
export const SET_KEY_SUGGESTIONS = 'SET_KEY_SUGGESTIONS';
export const SET_KEYS_FIELDS = 'SET_KEYS_FIELDS';
export const SET_LOADING = 'SET_LOADING';
export const SET_MAPS_FIELDS = 'SET_MAPS_FIELDS';
export const SET_NOTIFICATION_FIELDS = 'SET_NOTIFICATION_FIELDS';
export const SET_OPEN_API_FIELDS = 'SET_OPEN_API_FIELDS';
export const SET_COMMENT_FIELDS = 'SET_COMMENT_FIELDS';
export const SET_PREDEFINED_CHECKLIST_SUGGESTIONS = 'SET_PREDEFINED_CHECKLIST_SUGGESTIONS';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_STORAGE_FIELDS = 'SET_PROFILE_STORAGE_FIELDS';
export const SET_RECURRING_BOOKINGS = 'SET_RECURRING_BOOKINGS';
export const SET_SERVICE_SUGGESTIONS = 'SET_SERVICE_SUGGESTIONS';
export const SET_SUPER_ADMIN_RED_DAY_FIELDS = 'SET_SUPER_ADMIN_RED_DAY_FIELDS';
export const SET_REPORTS_FIELDS = 'SET_REPORTS_FIELDS';
export const SET_REPORTS_BOOKINGS_FIELDS = 'SET_REPORTS_BOOKINGS_FIELDS';
export const SET_REPORTS_EMPLOYEES_FIELDS = 'SET_REPORTS_EMPLOYEES_FIELDS';
export const SET_REQUESTS_FIELDS = 'SET_REQUESTS_FIELDS';
export const SET_SALARY_CODE_FIELDS = 'SET_SALARY_CODE_FIELDS';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_SERVICES_FIELDS = 'SET_SERVICES_FIELDS';
export const SET_SMART_SCHEDULE_FIELDS = 'SET_SMART_SCHEDULE_FIELDS';
export const SET_SMS_FIELDS = 'SET_SMS_FIELDS';
export const SET_SMS_HISTORY_FIELDS = 'SET_SMS_HISTORY_FIELDS';
export const SET_SMS_SCHEDULE_FIELDS = 'SET_SMS_SCHEDULE_FIELDS';
export const SET_SMS_TEMPLATE_FIELDS = 'SET_SMS_TEMPLATE_FIELDS';
export const SET_SNACKBAR_ERROR = 'SET_SNACKBAR_ERROR';
export const SET_SNACKBAR_INFO = 'SET_SNACKBAR_INFO';
export const SET_SNACKBAR_WARNING = 'SET_SNACKBAR_WARNING';
export const SET_SNACKBAR_SUCCESS = 'SET_SNACKBAR_SUCCESS';
export const SET_SUPER_ADMIN_APP_VERSION_FIELDS = 'SET_SUPER_ADMIN_APP_VERSION_FIELDS';
export const SET_SUPER_ADMIN_BOOKING_AUTO_EXTEND_ERROR_FIELDS = 'SET_SUPER_ADMIN_BOOKING_AUTO_EXTEND_ERROR_FIELDS';
export const SET_SUPER_ADMIN_COMPANY_SETTINGS_FIELDS = 'SET_SUPER_ADMIN_COMPANY_SETTINGS_FIELDS';
export const SET_SUPER_ADMIN_CUSTOMERS_FIELDS = 'SET_SUPER_ADMIN_CUSTOMERS_FIELDS';
export const SET_SUPER_ADMIN_ERRORS = 'SET_SUPER_ADMIN_ERRORS';
export const SET_SUPER_ADMIN_FIELDS = 'SET_SUPER_ADMIN_FIELDS';
export const SET_SUPER_ADMIN_INVOICES_FIELDS = 'SET_SUPER_ADMIN_INVOICES_FIELDS';
export const SET_SUPER_ADMIN_ITEMS_FIELDS = 'SET_SUPER_ADMIN_ITEMS_FIELDS';
export const SET_SUPER_ADMIN_PERMISSIONS_FIELDS = 'SET_SUPER_ADMIN_PERMISSIONS_FIELDS';
export const SET_SUPER_ADMIN_SMS_FIELDS = 'SET_SUPER_ADMIN_SMS_FIELDS';
export const SET_SUPER_ADMIN_STORAGE_FIELDS = 'SET_SUPER_ADMIN_STORAGE_FIELDS';
export const SET_SUPER_ADMIN_MOBILE_LOGIN_HISTORY_FIELDS = 'SET_SUPER_ADMIN_MOBILE_LOGIN_HISTORY_FIELDS';
export const SET_SUPER_ADMIN_USER_SUGGESTIONS = 'SET_SUPER_ADMIN_USER_SUGGESTIONS';
export const SET_SUPER_ADMIN_WEB_APP_VERSION_FIELDS = 'SET_SUPER_ADMIN_WEB_APP_VERSION_FIELDS';
export const SET_SUPER_ADMIN_WEB_LOGS_FIELDS = 'SET_SUPER_ADMIN_WEB_LOGS_FIELDS';
export const SET_SUPERVISOR_SUGGESTIONS = 'SET_SUPERVISOR_SUGGESTIONS';
export const SET_TASK_SUGGESTIONS = 'SET_TASK_SUGGESTIONS';
export const SET_TASK_CATEGORY_SUGGESTIONS = 'SET_TASK_CATEGORY_SUGGESTIONS';
export const SET_TIME_MANAGEMENT_FIELDS = 'SET_TIME_MANAGEMENT_FIELDS';
export const SET_TIME_MANAGEMENT_OVERVIEW_FIELDS = 'SET_TIME_MANAGEMENT_OVERVIEW_FIELDS';
export const SET_TIME_MANAGEMENT_PAYROLL_FIELDS = 'SET_TIME_MANAGEMENT_PAYROLL_FIELDS';
export const SET_TIME_MANAGEMENT_SUMMARY_FIELDS = 'SET_TIME_MANAGEMENT_SUMMARY_FIELDS';
export const SET_TIME_MANAGEMENT_SUMMARY_BOOKING_TIME_FIELDS = 'SET_TIME_MANAGEMENT_SUMMARY_BOOKING_TIME_FIELDS';
export const SET_TIME_MANAGEMENT_SUMMARY_INTERNAL_TIME_FIELDS = 'SET_TIME_MANAGEMENT_SUMMARY_INTERNAL_TIME_FIELDS';
export const SET_TIME_REPORTS_FIELDS = 'SET_TIME_REPORTS_FIELDS';
export const SET_USERS_AREA_FIELDS = 'SET_USERS_AREA_FIELDS';
export const SET_USERS_FIELDS = 'SET_USERS_FIELDS';
export const SET_BILLING_USERS_FIELDS = 'SET_BILLING_USERS_FIELDS';
export const SET_USERS_BOOKING_TIME_FIELDS = 'SET_USERS_BOOKING_TIME_FIELDS';
export const SET_USERS_INTERNAL_TIME_FIELDS = 'SET_USERS_INTERNAL_TIME_FIELDS';
export const SET_USERS_BOOKING_TIME_FILTERS = 'SET_USERS_BOOKING_TIME_FILTERS';
export const SET_USERS_INTERNAL_TIME_FILTERS = 'SET_USERS_INTERNAL_TIME_FILTERS';
export const SET_USERS_REQUEST_FIELDS = 'SET_USERS_REQUEST_FIELDS';
export const SET_USERS_REQUEST_FILTERS = 'SET_USERS_REQUEST_FILTERS';
export const SET_USERS_USER_GROUP_FIELDS = 'SET_USERS_USER_GROUP_FIELDS';
export const SET_USERS_DEPARTMENT_FIELDS = 'SET_USERS_DEPARTMENT_FIELDS';
export const SET_USERS_LOGS_FIELDS = 'SET_USERS_LOGS_FIELDS';
export const SET_USERS_PROFILE_IMAGES = 'SET_USERS_PROFILE_IMAGES';
export const SET_VISMA_ITEM_NUMBER_SUGGESTIONS = 'SET_VISMA_ITEM_NUMBER_SUGGESTIONS';
export const SET_VISMA_ITEM_SUGGESTIONS = 'SET_VISMA_ITEM_SUGGESTIONS';
export const SET_TASKS_FIELDS = 'SET_TASKS_FIELDS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const TOGGLE_DEVICE = 'TOGGLE_DEVICE';
export const SET_CHECKLIST_FIELDS = 'SET_CHECKLIST_FIELDS';

export const resetApplicationUIState = () => ({ type: RESET_APPLICATION_UI_STATE });
export const resetAuthFields = () => ({ type: RESET_AUTH_FIELDS });
export const resetFilters = (name) => ({ type: RESET_FILTERS, name });
export const resetSnackbar = () => ({ type: RESET_SNACKBAR });
export const setApplicationUIState = (payload) => ({ payload, type: SET_APPLICATION_UI_STATE });
export const setAreaSuggestions = (payload) => ({ payload, type: SET_AREA_SUGGESTIONS });
export const setCustomerAreaSuggestions = (payload) => ({ payload, type: SET_CUSTOMER_AREA_SUGGESTIONS });
export const setCustomerZipcodeSuggestions = (payload) => ({ payload, type: SET_CUSTOMER_ZIP_CODE_SUGGESTIONS });
export const setSkillsSuggestions = (payload) => ({ payload, type: SET_SKILLS_SUGGESTIONS });
export const setApplicationFields = (payload) => ({ payload, type: SET_APPLICATION_FIELDS });
export const setAuthFields = (payload) => ({ payload, type: SET_AUTH_FIELDS });
export const setAutoCompleteFields = (payload) => ({ payload, type: SET_AUTOCOMPLETE_FIELDS });
export const setBigCalendarFields = (payload) => ({ payload, type: SET_BIG_CALENDAR_FIELDS });
export const setBookingDocumentsFields = (payload) => ({ payload, type: SET_BOOKING_DOCUMENTS_FIELDS });
export const setBookingSmsHistoryFields = (payload) => ({ payload, type: SET_BOOKING_SMS_HISTORY_FIELDS });
export const setBookingsCustomerRequests = (payload) => ({ payload, type: SET_BOOKINGS_CUSTOMER_REQUESTS });
export const setBookingsFields = (payload) => ({ payload, type: SET_BOOKINGS_FIELDS });
export const setBookingInvoiceIdFields = (payload) => ({ payload, type: SET_BOOKING_INVOICE_ID_FIELDS });
export const setTagsFields = (payload) => ({ payload, type: SET_TAGS_FIELDS });
export const setBookingsItemsFields = (payload) => ({ payload, type: SET_BOOKINGS_ITEMS_FIELDS });
export const setBookingsPdfFields = (payload) => ({ payload, type: SET_BOOKINGS_PDF_FIELDS });
export const setCalendarFields = (payload) => ({ payload, type: SET_CALENDAR_FIELDS });
export const setCustomerDocumentsFields = (payload) => ({ payload, type: SET_CUSTOMER_DOCUMENTS_FIELDS });
export const setChecklistCollectionSuggestions = (payload) => ({ payload, type: SET_CHECKLIST_COLLECTION_SUGGESTIONS });
export const setColorFields = (payload) => ({ type: SET_COLOR_FIELDS, payload });
export const setCompanyBillingInfoFields = (payload) => ({ payload, type: SET_COMPANY_BILLING_INFO_FIELDS });
export const setOrganizationSettingFields = (payload) => ({ payload, type: SET_ORGANIZATION_SETTING_FIELDS });
export const setConfirmModalFields = (payload) => ({ payload, type: SET_CONFIRM_MODAL_FIELDS });
export const setConstitutionFields = (payload) => ({ payload, type: SET_CONSTITUTION_FIELDS });
export const setCustomerKeyHistoryFields = (payload) => ({ payload, type: SET_CUSTOMER_KEY_HISTORY_FIELDS });
export const setCustomerRequestsFields = (payload) => ({ payload, type: SET_CUSTOMER_REQUESTS_FIELDS });
export const setCustomerSuggestions = (payload) => ({ payload, type: SET_CUSTOMER_SUGGESTIONS });
export const setCustomers = (payload) => ({ payload, type: SET_CUSTOMERS });
export const setCustomersFields = (payload) => ({ payload, type: SET_CUSTOMERS_FIELDS });
export const setCustomerBookingFields = (payload) => ({ payload, type: SET_CUSTOMER_BOOKING_FIELDS });
export const setCustomerBookingFilters = (payload) => ({ payload, type: SET_CUSTOMER_BOOKING_FILTERS });
export const setCustomerRecurringBookingFields = (payload) => (
  { payload, type: SET_CUSTOMER_RECURRING_BOOKING_FIELDS }
);
export const setCustomerRecurringBookingFilters = (payload) => (
  { payload, type: SET_CUSTOMER_RECURRING_BOOKING_FILTERS }
);
export const setCustomerInvoiceHistoryFields = (payload) => (
  { payload, type: SET_CUSTOMER_INVOICE_HISTORY_FIELDS }
);
export const setCustomerInvoiceHistoryFilters = (payload) => (
  { payload, type: SET_CUSTOMER_INVOICE_HISTORY_FILTERS }
);
export const setDepartmentSuggestions = (payload) => ({ payload, type: SET_DEPARTMENT_SUGGESTIONS });
export const setEmployeeAvailabilityFields = (payload) => ({ payload, type: SET_EMPLOYEE_AVAILABILITY_FIELDS });
export const setPaginatedEmployeeAvailabilityFields = (payload) => (
  { payload, type: SET_PAGINATED_EMPLOYEE_AVAILABILITY_FIELDS }
);
export const setEmployeeDocumentsFields = (payload) => ({ payload, type: SET_EMPLOYEE_DOCUMENTS_FIELDS });
export const setEmployeeSuggestions = (payload) => ({ payload, type: SET_EMPLOYEE_SUGGESTIONS });
export const setEmployeeHourReportFields = (payload) => ({ payload, type: SET_EMPLOYEE_HOUR_REPORT_FIELDS });
export const setEmployeeViewTimeManagementFields = (payload) => (
  { payload, type: SET_EMPLOYEE_VIEW_TIMEMANAGEMENT_FIELDS }
);
export const setEmployeeViewShiftTimeFields = (payload) => ({ payload, type: SET_EMPLOYEE_VIEW_SHIFT_TIMES_FIELDS });
export const setEmployeeViewOvertimeFields = (payload) => ({ payload, type: SET_EMPLOYEE_VIEW_OVERTIME_FIELDS });
export const setExtendableRecurringBookingsFields = (payload) => (
  { payload, type: SET_EXTENDABLE_RECURRING_BOOKINGS_FIELDS }
);
export const setExtendableBookingsFields = (payload) => ({ payload, type: SET_EXTENDABLE_BOOKINGS_FIELDS });
export const setFilters = (payload) => ({ payload, type: SET_FILTERS });
export const setBreakTimeSettingFields = (payload) => ({ payload, type: SET_BREAK_TIME_SETTING_FIELDS });
export const setFortnoxAccountsFields = (payload) => ({ payload, type: SET_FORTNOX_ACCOUNTS_FIELDS });
export const setFortnoxItemSuggestions = (payload) => ({ type: SET_FORTNOX_ITEM_SUGGESTIONS, payload });
export const setHandledRecurringBookingsFields = (payload) => (
  { payload, type: SET_HANDLED_RECURRING_BOOKINGS_FIELDS }
);
export const setHistoryData = (payload) => ({ payload, type: SET_HISTORY_DATA });
export const setHistoryFields = (payload) => ({ payload, type: SET_HISTORY_FIELDS });
export const setInfoModalFields = (payload) => ({ payload, type: SET_INFO_MODAL_FIELDS });
export const setInitialFilters = (payload) => ({ payload, type: SET_INITIAL_FILTERS });
export const setInvoicesFields = (payload) => ({ payload, type: SET_INVOICES_FIELDS });
export const setInvoicesHistoryFields = (payload) => ({ payload, type: SET_INVOICES_HISTORY_FIELDS });
export const setItemSuggestions = (payload) => ({ payload, type: SET_ITEM_SUGGESTIONS });
export const setItems = (payload) => ({ payload, type: SET_ITEMS });
export const setItemsFields = (payload) => ({ payload, type: SET_ITEMS_FIELDS });
export const setKeySuggestions = (payload) => ({ payload, type: SET_KEY_SUGGESTIONS });
export const setKeysFields = (payload) => ({ payload, type: SET_KEYS_FIELDS });
export const setLoading = (isLoading) => ({ type: SET_LOADING, isLoading });
export const setMaintenanceBanner = (payload) => ({ payload, type: SET_APPLICATION_FIELDS });
export const setMapsFields = (payload) => ({ payload, type: SET_MAPS_FIELDS });
export const setNotificationFields = (payload) => ({ payload, type: SET_NOTIFICATION_FIELDS });
export const setOpenApiFields = (payload) => ({ payload, type: SET_OPEN_API_FIELDS });
export const setCommentFields = (payload) => ({ payload, type: SET_COMMENT_FIELDS });
export const setProfile = (payload) => ({ payload, type: SET_PROFILE });
export const setProfileStorageFields = (payload) => ({ payload, type: SET_PROFILE_STORAGE_FIELDS });
export const setRecurringBookingsFields = (payload) => ({ payload, type: SET_RECURRING_BOOKINGS });
export const setReportsFields = (payload) => ({ payload, type: SET_REPORTS_FIELDS });
export const setReportsBookingFields = (payload) => ({ payload, type: SET_REPORTS_BOOKINGS_FIELDS });
export const setReportsEmployeeFields = (payload) => ({ payload, type: SET_REPORTS_EMPLOYEES_FIELDS });
export const setRequestsFields = (payload) => ({ payload, type: SET_REQUESTS_FIELDS });
export const setSalaryCodeFields = (payload) => ({ payload, type: SET_SALARY_CODE_FIELDS });
export const setServices = (payload) => ({ payload, type: SET_SERVICES });
export const setServiceSuggestions = (payload) => ({ payload, type: SET_SERVICE_SUGGESTIONS });
export const setServicesFields = (payload) => ({ payload, type: SET_SERVICES_FIELDS });
export const setSmartScheduleFields = (payload) => ({ payload, type: SET_SMART_SCHEDULE_FIELDS });
export const setSmsFields = (payload) => ({ payload, type: SET_SMS_FIELDS });
export const setSmsHistoryFields = (payload) => ({ payload, type: SET_SMS_HISTORY_FIELDS });
export const setSmsScheduleFields = (payload) => ({ payload, type: SET_SMS_SCHEDULE_FIELDS });
export const setSmsTemplateFields = (payload) => ({ payload, type: SET_SMS_TEMPLATE_FIELDS });
export const setSnackbarError = (message) => ({ message, type: SET_SNACKBAR_ERROR });
export const setSnackbarInfo = (message) => ({ message, type: SET_SNACKBAR_INFO });
export const setSnackbarSuccess = (message) => ({ message, type: SET_SNACKBAR_SUCCESS });
export const setSnackbarWarning = (message) => ({ message, type: SET_SNACKBAR_WARNING });
export const setSuperAdminErrors = (payload) => ({ payload, type: SET_SUPER_ADMIN_ERRORS });
export const setSuperAdminFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_FIELDS });
export const setSuperAdminCustomersFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_CUSTOMERS_FIELDS });
export const setSuperAdminInvoicesFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_INVOICES_FIELDS });
export const setSuperAdminItemsFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_ITEMS_FIELDS });
export const setUserFeedbackFields = (payload) => ({ payload, type: SET_USER_FEEDBACK_FIELDS });
export const setSuperAdminAppVersionFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_APP_VERSION_FIELDS });
export const setSuperAdminBookingAutoExtendErrorFields = (payload) => (
  { payload, type: SET_SUPER_ADMIN_BOOKING_AUTO_EXTEND_ERROR_FIELDS }
);
export const setSuperAdminCompanySettingsFields = (payload) => (
  { payload, type: SET_SUPER_ADMIN_COMPANY_SETTINGS_FIELDS }
);
export const setSuperAdminPermissionsFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_PERMISSIONS_FIELDS });
export const setSuperAdminSMSFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_SMS_FIELDS });
export const setSuperAdminStorageFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_STORAGE_FIELDS });
export const setSuperAdminMobileLoginHistoryFields = (payload) => (
  { payload, type: SET_SUPER_ADMIN_MOBILE_LOGIN_HISTORY_FIELDS }
);
export const setSuperAdminUserSuggestions = (payload) => (
  { payload, type: SET_SUPER_ADMIN_USER_SUGGESTIONS }
);
export const setSuperAdminWebAppLogsFields = (payload) => (
  { payload, type: SET_SUPER_ADMIN_WEB_LOGS_FIELDS }
);
export const setSuperAdminWebAppVersionFields = (payload) => (
  { payload, type: SET_SUPER_ADMIN_WEB_APP_VERSION_FIELDS }
);
export const setSupervisorSuggestions = (payload) => ({ payload, type: SET_SUPERVISOR_SUGGESTIONS });
export const setSuperAdminRedDayFields = (payload) => ({ payload, type: SET_SUPER_ADMIN_RED_DAY_FIELDS });
export const setTimeManagementFields = (payload) => ({ payload, type: SET_TIME_MANAGEMENT_FIELDS });
export const setTimeManagementOverviewFields = (payload) => ({ payload, type: SET_TIME_MANAGEMENT_OVERVIEW_FIELDS });
export const setTimeManagementPayrollFields = (payload) => ({ payload, type: SET_TIME_MANAGEMENT_PAYROLL_FIELDS });
export const setTimeManagementSummaryFields = (payload) => ({ payload, type: SET_TIME_MANAGEMENT_SUMMARY_FIELDS });
export const setTimeManagementSummaryBookingTimeFields = (payload) => (
  { payload, type: SET_TIME_MANAGEMENT_SUMMARY_BOOKING_TIME_FIELDS }
);
export const setTimeManagementSummaryInternalTimeFields = (payload) => (
  { payload, type: SET_TIME_MANAGEMENT_SUMMARY_INTERNAL_TIME_FIELDS }
);
export const setTimeReportsFields = (payload) => ({ payload, type: SET_TIME_REPORTS_FIELDS });
export const setUsersBookingTimeFields = (payload) => ({ type: SET_USERS_BOOKING_TIME_FIELDS, payload });
export const setUsersInternalTimeFields = (payload) => ({ type: SET_USERS_INTERNAL_TIME_FIELDS, payload });
export const setUsersBookingTimeFilters = (payload) => ({ type: SET_USERS_BOOKING_TIME_FILTERS, payload });
export const setUsersInternalTimeFilters = (payload) => ({ type: SET_USERS_INTERNAL_TIME_FILTERS, payload });
export const setUsersProfileImages = (payload) => ({ type: SET_USERS_PROFILE_IMAGES, payload });
export const setUsersRequestFields = (payload) => ({ type: SET_USERS_REQUEST_FIELDS, payload });
export const setUsersRequestFilters = (payload) => ({ type: SET_USERS_REQUEST_FILTERS, payload });
export const setUsersFields = (payload) => ({ type: SET_USERS_FIELDS, payload });
export const setBillingUsersFields = (payload) => ({ type: SET_BILLING_USERS_FIELDS, payload });
export const setUsersUserGroupFields = (payload) => ({ type: SET_USERS_USER_GROUP_FIELDS, payload });
export const setUsersDepartmentFields = (payload) => ({ type: SET_USERS_DEPARTMENT_FIELDS, payload });
export const setUsersAreaFields = (payload) => ({ type: SET_USERS_AREA_FIELDS, payload });
export const setUserGroupsFields = (payload) => ({ payload, type: SET_USER_GROUPS_FIELDS });
export const setUserGroupSuggestions = (payload) => ({ payload, type: SET_USER_GROUP_SUGGESTIONS });
export const setUsersLogsFields = (payload) => ({ type: SET_USERS_LOGS_FIELDS, payload });
export const setVismaItemSuggestions = (payload) => ({ type: SET_VISMA_ITEM_SUGGESTIONS, payload });
export const setVismaItemNumberSuggestions = (payload) => ({ type: SET_VISMA_ITEM_NUMBER_SUGGESTIONS, payload });
export const setTasksFields = (payload) => ({ type: SET_TASKS_FIELDS, payload });
export const toggleDevice = (payload) => ({ payload, type: TOGGLE_DEVICE });
export const setChecklistFields = (payload) => ({ payload, type: SET_CHECKLIST_FIELDS });
export const setTaskSuggestions = (payload) => ({ type: SET_TASK_SUGGESTIONS, payload });
export const setTaskCategorySuggestions = (payload) => ({ type: SET_TASK_CATEGORY_SUGGESTIONS, payload });
export const setPredefinedChecklistSuggestions = (payload) => ({ type: SET_PREDEFINED_CHECKLIST_SUGGESTIONS, payload });
