import camelize from 'camelize';
import { pick, get, isEmpty, orderBy } from 'utils/lodash';
import snakify from 'utils/snakify';
import {
  epochTimeToFloat,
  formatDate,
  formatStrings,
  floatToEpochTime,
  getWeekdayOfMonth,
  getHourMinutesInUnixTime,
  getTimeFromNumber,
  getDifferenceBetweenTime,
  getUnixTimeInHoursMinutes,
} from 'utils/dateTime';
import { monthRepeatType, monthWeek } from 'utils/enum';
import { getSMSBodyForAPIRequest, getFormattedScheduledData } from 'appRedux/thunks/sms/selector';
import { getFormattedCustomerData } from 'appRedux/selectors/customer';
import getItemsTotalCosts, { getItemCost } from 'components/Booking/BookingModal/PriceTab/getTotalCosts';
import {
  getFormattedPaginationFields,
  getFilterCustomerId,
  getFilterEmployeeId,
  getFilterTagId,
  parseJson,
} from 'appRedux/selectors/utility';
import { getStringForJsonBookingNotes } from '../utility';

export const pickBookingModalFields = (booking) => {
  const bookingItems = (booking.bookingItems || [])
    .map(({ unit, ...rest }) => ({
      ...rest,
      itemDiscount: rest.discountValue,
      ...(rest.integrationDetail && { integrationDetail: parseJson(rest.integrationDetail) }),
      unit: unit === 'tim' ? 'h' : unit,
    }))
    .map((item) => ({ ...item, ...getItemCost(item, booking) }));
  const bookingPriceAttributes = getItemsTotalCosts(bookingItems, booking);
  return {
    ...booking,
    notes: getStringForJsonBookingNotes(parseJson(booking.notes, booking.notes)),
    smsScheduled: Boolean(booking.smsScheduled),
    budgetTime: getTimeFromNumber(booking.budgetTime),
    commented: Boolean(booking.commented),
    bookingItems,
    bookingPriceAttributes,
  };
};

export const getFormatedBookingProgressData = (records = []) => records.map((booking) => ({
  ...pickBookingModalFields(booking),
  employeeData: parseJson(booking.employeeData),
}));

export const getFormatedRecurringBookingPaginatedData = (response) => ({
  ...getFormattedPaginationFields(response.recurrences),
  recurring: getFormatedBookingProgressData(response.recurrences.records),
  recurrenceStartDate: get('recurrences.records[0].recurrencesStartDate')(response),
});

export const getFormattedBookingChecklists = (response) => Object.values(response).map((bookingChecklist) => ({
  taskCategory: {
    id: bookingChecklist[0].taskCategoryId,
    title: bookingChecklist[0].taskCategoryTitle,
  },
  tasks: orderBy('orderNumber', 'asc')(bookingChecklist.map((task, idx) => ({
    bookingTaskId: task.id,
    id: task.taskId,
    title: task.taskName,
    completed: task.completed,
    orderNumber: task.orderNumber ? task.orderNumber : idx + 1,
    checklistId: task.checklistId,
    taskCategoryId: task.taskCategoryId,
    connected: task.connected ?? true,
  }))),
}));

export const getFormattedPredefinedChecklist = (response) => ({
  checklist: {
    taskCategory: response.task_category,
    tasks: orderBy('orderNumber', 'asc')(response.tasks.map((task, idx) => ({
      id: task.id,
      title: task.name,
      isCompleted: Boolean(task.is_completed),
      orderNumber: task.order_number ? task.order_number : idx + 1,
      connected: task.connected ?? true,
    }))),
  },
});

export const getFormattedBookingData = ({ booking, is_extend: isExtend, sms_scheduled: smsScheduled }) => ({
  ...booking,
  ...pickBookingModalFields(booking),
  invoiceHours: booking.invoiceHours ? epochTimeToFloat(booking.invoiceHours) : null,
  showInvoiceTime: Boolean(booking.invoiceHours),
  smsScheduled: Boolean(smsScheduled),
  isExtendable: isExtend,
  totalHours: getUnixTimeInHoursMinutes(booking.totalHours),
  smsSchedule: {
    scheduleCriteria: get('smsSchedule.scheduleCriteria')(booking),
  },
  bookingAddresses: camelize(booking.bookingAddresses) || [],
  totalEmployeeWorkTime: getUnixTimeInHoursMinutes((booking.bookingsEmployees || []).reduce((tot, emp) => {
    tot += getHourMinutesInUnixTime(emp.paidTime);
    return tot;
  }, 0)),
  bookingEmployees: (booking.bookingsEmployees || []).map((employee) => ({
    ...employee,
    breakTime: getTimeFromNumber(employee.breakTime),
    startDate: booking.startDate,
    invoiceTime: employee.invoiceTime ? epochTimeToFloat(employee.invoiceTime) : null,
    employeePunch: booking.punches.filter((punch) => punch.employeeId === employee.employeeId)[0],
    overtimes: employee.overtimes && employee.overtimes.map((overtime) => ({
      ...overtime,
      startTime: getUnixTimeInHoursMinutes(overtime.startTime),
      endTime: getUnixTimeInHoursMinutes(overtime.endTime),
    })),
    ignoreOvertime: employee.ignoreOvertime,
  })),
  scheduledMessage: booking.scheduledMessage ? getFormattedScheduledData(booking.scheduledMessage) : null,
  bookingService: {
    ...booking.bookingService,
    colorCode: booking.bookingService.color.code,
    colorCodeId: booking.bookingService.color.id,
  },
  customer: getFormattedCustomerData(booking.customer),
  bookingTags: camelize(booking.bookingTags.map((tag) => ({ ...tag, title: tag.tagTitle }))),
  autoExtend: booking.autoExtend,
  bookingChecklists: getFormattedBookingChecklists(booking.bookingChecklists),
});

const getFormattedListBookingData = (bookings = []) => bookings.map((booking) => {
  const employeeData = JSON.parse(booking.employee_data) || [];
  const tagData = JSON.parse(booking.tags_data) || [];
  const bookingEbokaDetails = parseJson(booking.booking_eboka_details);
  return camelize({
    ...booking,
    bookingEbokaDetails,
    smsScheduled: Boolean(booking.sms_scheduled),
    employeeData,
    tagData,
  });
}, []);

export const formatBookingListResponse = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: response.records ? getFormattedListBookingData(response.records) : [],
});

export const formatRecurringListResponse = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: camelize(response.records),
});

export const getRecordsWithCustomerID = (records = [], customerData) => records.map((customer) => ({
  ...camelize(customer),
  customerId: customerData.customerId,
  customerName: customerData.customerName,
}));

export const formatRecurringListResponseDetails = (response = {}, customer) => ({
  ...getFormattedPaginationFields(response),
  records: getRecordsWithCustomerID(response.records, customer),
});

export const formatBookingChecklistApiReq = (bookingChecklists = [], bookingId) => {
  let checklist = [];
  bookingChecklists.map((bookingChecklist) => {
    checklist = [...checklist, ...bookingChecklist.tasks.map((task) => ({
      id: bookingId && task.bookingTaskId,
      task_id: task.id,
      task_category_id: bookingChecklist.taskCategory.id,
      _destroy: bookingChecklist.taskCategory.destroy || task.destroy,
      completed: task.completed,
      order_number: task.orderNumber,
    }))];
    return checklist;
  });
  return checklist;
};

export const getFormattedBookingDataForApiRequest = (booking) => { // eslint-disable-line
  const activeBookingsEmployees = (booking.bookingEmployees || []).filter(emp => !emp.destroy); // eslint-disable-line

  return {
    future_bookings: booking.futureBookings,
    all_bookings: booking.allBookings,
    fields_to_update: booking.fieldsToUpdate && {
      booking_service: booking.fieldsToUpdate.bookingService,
      customer: booking.fieldsToUpdate.customer,
      booking_date_time_fields: booking.fieldsToUpdate.bookingDateTimeFields,
      update_all: booking.fieldsToUpdate.updateAll,
      booking_tags: booking.fieldsToUpdate.bookingTags,
      notes: booking.fieldsToUpdate.notes,
      price: booking.fieldsToUpdate.price,
      booking_employees: booking.fieldsToUpdate.bookingEmployees,
      booking_addresses: booking.fieldsToUpdate.bookingAddresses,
      booking_checklists: booking.fieldsToUpdate.bookingChecklists,
      published: booking.fieldsToUpdate.published,
      invoice_hours: booking.fieldsToUpdate.invoiceHours,
      employee_confirmation_required: booking.fieldsToUpdate.employeeConfirmationRequired,
    },
    sms_or_email_params: {
      email: Boolean(booking.emailFieldsToInclude),
      ...(booking.emailFieldsToInclude && snakify({ fieldsToInclude: booking.emailFieldsToInclude })),
      sms: booking.sendSmsData && getSMSBodyForAPIRequest(booking.sendSmsData)?.scheduled_message,
    },
    booking: {
      proposal_id: booking.proposalId,
      auto_extend: booking.autoExtend,
      auto_update_invoice_time: booking.autoUpdateInvoiceTime,
      published: booking.published,
      invoice_hours: booking.showInvoiceTime ? floatToEpochTime(booking.invoiceHours) : null,
      customer_id: booking.hasOwnProperty('customer') ? booking.customer.id : undefined,
      customer_name: booking.customerName || booking.customer.name,
      service_id: get('bookingService.id')(booking),
      service: get('bookingService.title')(booking),
      vat_included: booking.vatIncluded,
      start_date: booking.startDate && booking.startDate,
      // user shouldn't be able to update endDate directly, you use extend booking if required
      end_date: (booking.id || !booking.isRecurring) ? undefined : booking.endDate,
      start_time: booking.startTime ? booking.startTime : undefined,
      end_time: booking.hasOwnProperty('endTime') ? (booking.endTime ?? null) : undefined,
      budget_time: getHourMinutesInUnixTime(booking.budgetTime),
      is_recurring: booking.isRecurring,
      repeat_type: booking.repeatType,
      repeat_interval: Number(booking.repeatInterval),
      notes: typeof booking.notes === 'object' ? JSON.stringify(booking.notes) : booking.notes,
      housework_type: booking.houseworkType,
      price_type: booking.priceType,
      month_repeat: booking.monthRepeat,
      time_type: booking.timeType,
      total_hours: booking.totalHours ? getHourMinutesInUnixTime(booking.totalHours) : undefined,
      flex_time_type: booking.flexTimeType,
      week_number: booking.monthRepeat === monthRepeatType.day
        ? monthWeek[getWeekdayOfMonth(booking.startDate)] : undefined,
      flexible_price_type: booking.flexiblePriceType,
      repeat_days: booking.repeatDays,
      items_count: booking.bookingItems && booking.bookingItems.length,
      employee_confirmation_required: booking.employeeConfirmationRequired,
      bookings_employees_attributes: booking.bookingEmployees && booking.bookingEmployees.map((emp) => ({
        employee_id: emp.employeeId,
        booking_employees_id: emp.bookingEmployeesId,
        id: emp.id,
        booking_id: booking.id,
        booking_start_time: emp.startTime ? emp.startTime : null,
        booking_end_time: emp.endTime ? emp.endTime : null,
        break_time: emp.breakTime ? getHourMinutesInUnixTime(emp.breakTime) : null,
        invoice_time: emp.invoiceTime ? floatToEpochTime(emp.invoiceTime) : null,
        paid_time: (emp.endTime && emp.startTime && emp.breakTime)
          ? getDifferenceBetweenTime(getDifferenceBetweenTime(emp.endTime, emp.startTime), emp.breakTime)
          : null,
        _destroy: emp.destroy,
        ignore_overtime: emp.ignoreOvertime,
      })),
      booking_items_attributes: booking.bookingItems && booking.bookingItems.map((item) => ({
        per_unit: item.perUnit,
        material_cost: item.materialCost,
        housework_type: item.houseworkType,
        is_house_work: item.isHouseWork,
        account_number: item.accountNumber,
        discount: item.discount,
        discount_value: item.itemDiscount,
        per_employee: item.perEmployee,
        ...(item.integrationDetail && {
          visma_item_id: item.integrationDetail.vismaId,
          material_cost: item.materialCost,
          fortnox_id: item.integrationDetail.fortnoxNumber,
        }),
        _destroy: item.destroy,
        ...pick(['id', 'quantity', 'title', 'vat', 'unit', 'hours'])(item),
      })),
      booking_addresses_attributes: booking.bookingAddresses && booking.bookingAddresses.map((bookingAddress) => ({
        id: bookingAddress.id,
        address: bookingAddress.address,
        title: bookingAddress.title,
        customer_id: bookingAddress.customerId,
        longitude: bookingAddress.longitude,
        latitude: bookingAddress.latitude,
        company_id: bookingAddress.companyId,
        _destroy: bookingAddress.destroy,
      })),
      booking_tags_attributes: booking.bookingTags && booking.bookingTags.map((tag) => ({
        id: tag.id,
        tag_id: tag.tagId,
        _destroy: tag.destroy,
      })),
      booking_checklists_attributes: booking.bookingChecklists
        && formatBookingChecklistApiReq(booking.bookingChecklists, booking.id),
      ...(booking.bookingEbokaDetails && {
        booking_eboka_detail_attributes: {
          order_id: booking.bookingEbokaDetails.orderId,
        },
      }),
    },
    booking_checklist_tasks: !isEmpty(booking.bookingChecklistTasks) && booking.bookingChecklistTasks.map((task) => ({
      id: task.bookingTaskId,
      task_id: task.id,
      name: task.title,
      task_category_id: task.taskCategoryId,
      connected: task.connected,
    })),
  };
};

export const getBookingActivity = (activities) => ({
  page: activities.page,
  perPage: activities.per_page,
  totalRecords: activities.total_records,
  records: !activities.records ? [] : activities.records.map(({ created_at: createdAt, ...restActivityFields }) => ({
    ...camelize(restActivityFields),
    createdAt: formatDate(createdAt, formatStrings.dateDashTime),
  }
  )),
});

export const getFormattedBookingFilterParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  customer_id: getFilterCustomerId(filters),
  employee_id: getFilterEmployeeId(filters),
  service_ids: filters.services?.map((service) => service.id),
  status: filters.bookingStatus,
  booking_id: filters.bookingId,
  invoice_type: filters.invoiceStatus,
  per_page: filters.perPage,
  page: filters.page,
  seen_us: filters.seenUs,
  advance_search: filters.bookingId,
  published: filters.publishStatus === 'view_all' ? undefined : filters.publishStatus,
  tag_id: getFilterTagId(filters),
  customer_area: filters.customerArea?.title,
});

export const getFormatedRecurringBookingFilterParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  per_page: filters.perPage,
  page: filters.page,
});

export const getFormattedRecurringFilterParams = (filters = {}) => ({
  customer_id: getFilterCustomerId(filters),
  advanced_filter: filters.startDate && filters.endDate ? 'view_all' : filters.advanceFilter,
  days: filters.days,
  interval: filters.interval,
  repeat_every: filters.repeatEvery,
  per_page: filters.perPage,
  page: filters.page,
  start_date: filters.startDate,
  end_date: filters.endDate,
});

export const getFormattedRecurringBookingFilterParams = (filters = {}) => ({
  customer_id: getFilterCustomerId(filters),
  advanced_filter: filters.advanceFilter,
  days: filters.days,
  interval: filters.interval,
  repeat_every: filters.repeatEvery,
  start_date: filters.startDate,
  end_date: filters.endDate,
});
