import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';

export const customerApi = createApi({
  reducerPath: 'customer',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCustomerExcelImportStatus: builder.query({
      query: () => ({ url: '/api/v3/customers/get_customer_import_status', method: 'GET' }),
    }),
  }),
});

export const { useGetCustomerExcelImportStatusQuery } = customerApi;
