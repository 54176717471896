import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError } from 'appRedux/actions';

export const requestOrdersSummary = createAsyncThunk(
  'order/fetchOrdersSummary',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const previousYearparams = {
        start_date: '2023-01-01',
        end_date: '2023-12-31',
      };
      const response = await extra.axios.get('api/v3/reports/orders_summary', { params });
      const previousYearResponse = await extra.axios.get(
        'api/v3/reports/orders_summary',
        { params: previousYearparams },
      );
      const { totalOrdersRevenue, recurringOrdersRevenue } = previousYearResponse.data.data;
      return {
        ...response.data.data,
        previousTotalOrdersRevenue: totalOrdersRevenue,
        previousRecurringOrdersRevenue: recurringOrdersRevenue,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestTop5CustomersByRevenue = createAsyncThunk(
  'reportsNext/fetchTopCustomerRevenueReports',
  async (filters, { extra, dispatch }) => {
    try {
      const query = {
        params: {
          start_date: filters.startDate,
          end_date: filters.endDate,
          order: 'desc',
          order_by: 'total_orders_revenue',
        },
      };
      const response = await extra.axios.get('/api/v3/reports/customer_revenue_report', query);
      const { records } = response.data.data;
      return { topCustomers: records.slice(0, 5) };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_customer_reports_failed'));
      return { records: [] };
    }
  },
);

export const requestCustomerOrganisationRevenueSummary = createAsyncThunk(
  'order/fetchCustomerorganisationRevenueSummary',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/reports/customer_oraganization_revenue_report', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestTop5Users = createAsyncThunk(
  'reportsNext/fetchTop5Users',
  async (filters, { extra, dispatch }) => {
    try {
      const query = {
        params: {
          start_date: filters.startDate,
          end_date: filters.endDate,
          order: 'desc',
          order_by: 'total_orders_duration',
        },
      };
      const response = await extra.axios.get('/api/v3/reports/employee_reports', query);
      const { records } = response.data.data;
      return { topUsers: records.slice(0, 5) };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_user_reports_failed'));
      return { records: [] };
    }
  },
);

export const requestAnnualOrdersSummaryCounts = createAsyncThunk(
  'order/fetchAnnualOrdersCounts',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/reports/annual_orders_report', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestProposalsSummary = createAsyncThunk(
  'order/fetchProposalSummary',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        date: filters.startDate,
        end_date: filters.endDate,
      };
      const previousYearparams = {
        date: '2023-01-01',
        end_date: '2023-12-31',
      };
      const response = await extra.axios.get('/api/v3/reports/proposal_report_stats', { params });
      const previousYearResponse = await extra.axios.get(
        '/api/v3/reports/proposal_report_stats',
        { params: previousYearparams },
      );
      const { winRate } = previousYearResponse.data.data;
      return { ...response.data.data, previousWinRate: winRate };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_proposal_reports_failed'));
      return rejectWithValue(err);
    }
  },
);

export const requestCustomerOrganisationProposalsSummary = createAsyncThunk(
  'order/fetchProposalsOrganisationSummary',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/reports/proposal_organisation_report', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_proposal_reports_failed'));
      return rejectWithValue(err);
    }
  },
);

export const requestUsersForAvatars = createAsyncThunk(
  'reportsNext/fetchUsers',
  async (filters, { extra, dispatch }) => {
    try {
      const query = {
        params: {
          page: 1,
          per_page: 10,
          is_active: true,
        },
      };
      const response = await extra.axios.get('/api/v1/users', query);
      const { records } = response.data.data;
      return { users: records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.users.gettingUsers.error'));
      return { users: [] };
    }
  },
);

export const requestAnnualUsersReports = createAsyncThunk(
  'order/fetchAnnualUsersReports',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/reports/annual_users_report');
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestAnnualRevenueReports = createAsyncThunk(
  'order/fetchAnnualRevenueReports',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('api/v3/reports/annual_revenue_increase_report', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_revenue_reports_failed'));
      return rejectWithValue(err);
    }
  },
);

export const requestAnnualTotalHoursPercent = createAsyncThunk(
  'order/fetchAnnualTotalHoursPercent',
  async (filters, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/reports/annual_orders_recurring_report', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_revenue_reports_failed'));
      return rejectWithValue(err);
    }
  },
);
