import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestCreateVirtualOrder, requestOrder } from 'appRedux/owner/orders/thunk';
import { setSnackbarSuccess, setSnackbarError, setFilters } from 'appRedux/actions';
import { setOrdersFields } from 'appRedux/owner/orders';
import { getFormattedListData } from 'appRedux/utility';
import { names } from 'utils/enum';
import { isEmpty } from 'utils/lodash';
import {
  getFormattedOpenShiftRequestBody,
  getFormattedEmployeeRequestsData,
  getFormattedOpenShiftListData,
} from './selector';

export const requestCreateOpenShiftForOrder = createAsyncThunk(
  'openShift/createOpenShift',
  async (data, { extra, dispatch }) => {
    try {
      const { details, order } = data;
      let orderId = order.id;
      if (order.virtual) {
        const params = {
          project_id: order.projectId,
          order_date: order.date,
        };
        const orderData = await requestCreateVirtualOrder(extra.axios, params, dispatch);
        orderId = orderData.id;
      }
      const requestBody = getFormattedOpenShiftRequestBody({ ...details, orderId });
      const response = await extra.axios.post('/api/v3/open_shifts', requestBody);
      dispatch(setSnackbarSuccess('snackbar_open_shift_created'));
      dispatch(setOrdersFields({ refreshAllOrders: {} }));
      dispatch(requestOrder({ orderId }));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shift_create_failed'));
    }
  },
);

export const requestUpdateOpenShiftForOrder = createAsyncThunk(
  'openShift/updateOpenShift',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedOpenShiftRequestBody(details);
      const response = await extra.axios.put('/api/v3/open_shifts', requestBody);
      dispatch(setSnackbarSuccess('snackbar_open_shift_updated'));
      dispatch(requestOrder({ orderId: details.orderId }));
      dispatch(setOrdersFields({ refreshAllOrders: {} }));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shift_update_failed'));
    }
  },
);

export const requestDeleteOpenShift = createAsyncThunk(
  'openShift/deleteOpenShift',
  async (orderId, { extra, dispatch }) => {
    try {
      const params = {
        order_id: orderId,
      };
      await extra.axios.delete('/api/v3/open_shifts', { params });
      dispatch(setSnackbarSuccess('snackbar_open_shift_deleted'));
      dispatch(setOrdersFields({ refreshAllOrders: {} }));
      dispatch(requestOrder({ orderId }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shift_delete_failed'));
    }
  },
);

export const requestOpenShiftEmployeeRequests = createAsyncThunk(
  'openShift/fetchEmployeeRequests',
  async (details, { extra, dispatch }) => {
    try {
      const params = {
        employee_name: details.query,
        order_id: details.orderId,
      };
      const response = await extra.axios.get('/api/v3/open_shifts/employees_request_status', { params });
      const formattedData = getFormattedEmployeeRequestsData(
        !isEmpty(response.data.data) ? response.data.data : [],
      );
      return { ...formattedData };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shift_employee_requests_error'));
    }
  },
);

export const requestOpenShifts = createAsyncThunk(
  'openShift/fetchOpenShifts',
  async (name, { extra, dispatch, getState }) => {
    const { filters } = extra.getState(name, getState);
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
        page: filters.page,
        per_page: filters.perPage,
      };
      const response = await extra.axios.get('/api/v3/open_shifts/open_shift_requests', { params });
      const { records, ...nextFilters } = getFormattedOpenShiftListData(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shift_employee_requests_error'));
    }
  },
);

export const requestOpenShiftsCount = createAsyncThunk(
  'openShift/fetchOpenShiftsCount',
  async (name, { extra, dispatch, getState }) => {
    const { filters } = extra.getState(name, getState);
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/open_shifts/open_shift_requests_count', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shifts_error'));
    }
  },
);

export const requestApproveEmployeesForOpenShift = createAsyncThunk(
  'openShift/approveEmployee',
  async (details, { extra, dispatch, getState }) => {
    const { openShifts } = getState();
    try {
      const requestBody = {
        open_shift_claim_ids: details.openShiftClaimIds,
        status: details.status,
        order_id: details.orderId,
      };
      const response = await extra.axios.post('/api/v3/open_shifts/approve_open_shift_claims', requestBody);
      dispatch(setSnackbarSuccess('snackbar_open_shift_employees_approved'));
      dispatch(requestOrder({ orderId: details.orderId }));
      dispatch(requestOpenShiftEmployeeRequests({ orderId: details.orderId }));
      if (openShifts.showShiftRequestsDialog) {
        dispatch(requestOpenShifts(names.shiftRequests));
        dispatch(requestOpenShiftsCount(names.shiftRequests));
      }
      dispatch(setOrdersFields({ refreshAllOrders: {} }));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shift_approve_failed'));
    }
  },
);

export const requestEmployeesForOpenShifts = createAsyncThunk(
  'openShift/fetchEmployeesForOpenShifts',
  async (details, { extra, dispatch, getState }) => {
    const { filters } = extra.getState(details.name, getState);
    try {
      const params = {
        page: filters.page || 1,
        per_page: filters.perPage || 10,
        query: filters.query,
        role: 'Employee',
        is_active: true,
        ids: details.ids,
      };
      const response = await extra.axios.get('/api/v1/users', { params });
      const { records, ...rest } = getFormattedListData(response.data.data);
      const filteredRecords = records.filter((record) => !details.ids.includes(record.id));
      dispatch(setFilters({ ...filters, ...rest, name: details.name }));
      return { records: filteredRecords };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_open_shift_employee_requests_error'));
    }
  },
);
