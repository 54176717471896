import { createApi } from '@reduxjs/toolkit/query/react';
import snakify from 'utils/snakify';
import { pick } from 'utils/lodash';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';

export const superAdminCompanyApi = createApi({
  reducerPath: 'superAdminCompanyApi',
  baseQuery: axiosBaseQuery(),
  selectFromResult: (state) => state.superUser.superAdminCompanyApi,
  endpoints: (builder) => ({
    getSuperAdminCompanies: builder.query({
      query: (filters) => ({
        url: '/api/v1/super_admin/companies',
        method: 'GET',
        params: snakify({
          ...pick(['page', 'perPage', 'query'])(filters),
          ...(filters.listCompaniesType !== 'view_all' && { [snakify(filters.listCompaniesType)]: true }),
        }),
      }),
    }),
    getSuperAdminCompany: builder.query({
      query: (companyId) => ({ url: `/api/v1/super_admin/companies/${companyId}`, method: 'GET' }),
    }),
    setSuperAdminCompanyTrialEndDate: builder.mutation({
      query: (companyData) => ({
        url: '/api/v1/super_admin/update_trial_end_date',
        method: 'PUT',
        data: snakify(companyData),
      }),
    }),
    getSuperAdminCompanyActiveSubscription: builder.query({
      query: (companyId) => ({ url: `/api/v3/super_admin/company/${companyId}/active_subscription`, method: 'GET' }),
    }),
    setSuperAdminCompanyActiveSubscription: builder.mutation({
      query: ({ companyId, ...body }) => ({
        url: `/api/v3/super_admin/company/${companyId}/update_active_subscription`,
        method: 'PUT',
        data: snakify(body),
      }),
    }),
    activateSuperAdminCompany: builder.query({
      query: (companyId) => ({ url: `/api/v1/super_admin/company_activate/${companyId}`, method: 'GET' }),
    }),
    deactivateSuperAdminCompany: builder.query({
      query: (companyId) => ({ url: `/api/v1/super_admin/company_deactivate/${companyId}`, method: 'GET' }),
    }),
    getCompanyFortnoxCustomer: builder.query({
      query: ({ companyId, fortnoxCustomerId }) => ({
        url: `/api/v3/super_admin/company/${companyId}/fetch_fortnox_customer`,
        method: 'GET',
        params: snakify({ fortnoxCustomerId }),
      }),
    }),
    setCompanyFortnoxCustomer: builder.mutation({
      query: (companyData) => ({
        url: `/api/v3/super_admin/company/${companyData.fortnoxCustomer.companyId}/update_fortnox_customer`,
        method: 'PUT',
        data: snakify(companyData),
      }),
    }),
    getCompanySubscriptionInvoiceHistory: builder.query({
      query: ({ companyId, pagination }) => ({
        url: `/api/v3/super_admin/company/${companyId}/subscription_invoice_history`,
        method: 'GET',
        params: snakify(pagination),
      }),
    }),
    getSuperAdminCompanyInvoiceConfig: builder.query({
      query: (companyId) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'GET',
        params: snakify({ companyId }),
      }),
    }),
    createSuperAdminCompanyInvoiceConfig: builder.mutation({
      query: ({ companyId, ...restData }) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'POST',
        data: snakify({
          companyId,
          companyInvoicingConfiguration: restData,
        }),
      }),
    }),
    updateSuperAdminCompanyInvoiceConfig: builder.mutation({
      query: ({ companyId, ...restData }) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'PUT',
        data: snakify({
          companyId,
          companyInvoicingConfiguration: restData,
        }),
      }),
    }),
    deleteSuperAdminCompanyInvoiceConfig: builder.mutation({
      query: (companyId) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'DELETE',
        params: snakify({ companyId }),
      }),
    }),
  }),
});

export const {
  useGetSuperAdminCompaniesQuery,
  useGetSuperAdminCompanyQuery,
  useSetSuperAdminCompanyTrialEndDateMutation,
  useGetSuperAdminCompanyActiveSubscriptionQuery,
  useSetSuperAdminCompanyActiveSubscriptionMutation,
  useLazyActivateSuperAdminCompanyQuery,
  useLazyDeactivateSuperAdminCompanyQuery,
  useGetCompanyFortnoxCustomerQuery,
  useSetCompanyFortnoxCustomerMutation,
  useLazyGetCompanyFortnoxCustomerQuery,
  useGetCompanySubscriptionInvoiceHistoryQuery,
  useLazyGetCompanySubscriptionInvoiceHistoryQuery,
  useGetSuperAdminCompanyInvoiceConfigQuery,
  useCreateSuperAdminCompanyInvoiceConfigMutation,
  useUpdateSuperAdminCompanyInvoiceConfigMutation,
  useDeleteSuperAdminCompanyInvoiceConfigMutation,
} = superAdminCompanyApi;
