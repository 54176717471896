/* eslint-disable camelcase */
import axios from 'utils/Api';
import userAxios from 'utils/userAxios';
import axiosNext from 'utils/axios';
import { storeAxios } from 'appRedux/store';
import { batch } from 'react-redux';
import initialFiltersState from 'appRedux/reducers/filters/initialState';
import { fm, deepTrim } from 'utils/string';
import { appRoutes, signUpSteps, userRoles } from 'utils/enum';
import { get, omit, isEmpty } from 'utils/lodash';
import {
  FETCH_ERROR,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  setAuthFields,
  setSnackbarError,
  setSnackbarSuccess,
  setInitialFilters,
  setSnackbarInfo,
} from 'appRedux/actions';
import {
  getFormattedUserDataForRegister,
  getDeviceDetails,
} from 'appRedux/thunks/auth/selector';
import { googleLogout } from '@react-oauth/google';

export const setInitUrl = (url) => ({
  payload: url,
  type: INIT_URL,
});

// Sign out
export const userSignOut = () => (dispatch) => {
  googleLogout();
  const userWasLoggedIn = Boolean(localStorage.getItem('token'));
  localStorage.removeItem('user_token');
  localStorage.removeItem('token');
  localStorage.removeItem('main_company_token');
  localStorage.removeItem('role');
  dispatch({ type: SIGNOUT_USER_SUCCESS });
  if (userWasLoggedIn) window.location.reload();
};

export const resetInitialFilters = () => (dispatch) => {
  try {
    const savedFilters = JSON.parse(localStorage.getItem('filters') || {});
    if (!savedFilters) return;

    const fieldsToOmit = ['startDate', 'endDate', 'date', 'page', 'view'];
    const names = Object.keys(savedFilters.names).reduce((result, name) => {
      const savedNamedFilters = omit(fieldsToOmit)(savedFilters.names[name]);
      result[name] = { ...initialFiltersState.names[name], ...savedNamedFilters };
      return result;
    }, {});

    dispatch(setInitialFilters({ names }));
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestSignupSteps = (history) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/registration_steps');
    if (!isEmpty(response.data.data.steps)) {
      const { step } = response.data.data.steps;
      dispatch(setAuthFields({ step }));
      if (history && step < signUpSteps.createEmployee) {
        history.push(appRoutes.companyDetails);
      }
    } else {
      dispatch(setAuthFields({ step: signUpSteps.createEmployee }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.auth.signup.steps.error'));
  }
};

export const requestDeleteRegistrationSteps = (history) => async (dispatch) => {
  try {
    await axios.delete('/api/v3/registration_steps');
    if (history) {
      history.replace(appRoutes.calendar);
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.registrationStep.delete.error'));
  }
};

const logUserLogin = () => async (dispatch, getState) => {
  const { auth: { loginType } } = getState();
  try {
    const login_detail = {
      devise_type: 2,
      last_login: new Date().toISOString(),
      login_type: loginType,
      ...getDeviceDetails(),
    };
    await axios.post('/api/v3/login_details', { login_detail });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_login_details_error'));
  }
};

export const requestEmailVerificationOtp = (email) => async (dispatch) => {
  try {
    sessionStorage.setItem('verification_email', email);
    await axios.post('/users/confirmation', { email });
    dispatch(setSnackbarSuccess('snackbar.auth.email.sent'));
  } catch (err) {
    if (get('response.data.errors[0].details.code')(err)) {
      const errorCode = get('response.data.errors[0].details.code')(err);
      return dispatch(setSnackbarError(errorCode));
    }
    dispatch(setSnackbarError('snackbar_email_verify_error'));
  }
};

export const requestCheckEmailVerificationOtp = (details, history) => async (dispatch) => {
  try {
    dispatch(setAuthFields({ fsip: true }));
    const requestBody = {
      email: details.email,
      otp: details.otp,
    };
    await axios.put('/users/confirmation', requestBody);
    dispatch(setSnackbarSuccess('snackbar_email_confirm_success'));
    history.push('/login');
    dispatch(setAuthFields({ fsip: false }));
    sessionStorage.removeItem('verification_email');
  } catch (err) {
    dispatch(setAuthFields({ fsip: false }));
    if (get('response.data.errors[0].details.code')(err)) {
      const errorCode = get('response.data.errors[0].details.code')(err);
      return dispatch(setSnackbarError(errorCode));
    }
    dispatch(setSnackbarError('snackbar_email_verify_error'));
  }
};

// Signin
export const userSignIn = (details, history) => async (dispatch) => {
  try {
    if (details.otp) sessionStorage.setItem('reset_password_otp', details.otp);
    sessionStorage.setItem('reset_password_email', details.email);
    const body = deepTrim({
      email: details.email,
      password: details.password,
      otp: details.otp,
    });
    const { data } = await axios.post('/api/v3/auth/login_new_flow', body);
    if (data.data.user.role === userRoles.superAdmin) {
      localStorage.setItem('role', data.data.user.role);
      localStorage.setItem('token', data.data.user.token);
      storeAxios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      axios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      axiosNext.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      dispatch(setAuthFields({
        role: data.data.user.role,
        token: data.data.user.token,
      }));
      history.replace('/');
      return;
    }
    userAxios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
    dispatch(setAuthFields({
      userToken: data.data.user.token,
    }));
    localStorage.setItem('user_token', data.data.user.token);
    history.push('/companies');
  } catch (error) {
    if (!error.response) return dispatch(setSnackbarError('snackbar.auth.internet.error'));

    if (error.response.status === 422) {
      const errorCode = get('response.data.errors[0].details.code')(error);
      if (errorCode === '1009') {
        dispatch(setSnackbarInfo(errorCode));
        dispatch(requestEmailVerificationOtp(details.email));
        history.push(appRoutes.emailVerification);
      } else {
        dispatch(setAuthFields(
          details.otp
            ? { loginAfterSetPasswordByOtp: true }
            : { loginAfterSetPasswordByDefaultPassword: true },
        ));
        history.push(appRoutes.changePassword);
        return dispatch(setSnackbarInfo('auth_login_422'));
      }
    }

    if ([423, 400].includes(error.response.status)) {
      const { status, data } = error.response;
      const remainingLoginAttempts = get('errors[0].details.remaining_attempts')(data);
      let loginError = null;
      if (status === 423) loginError = fm('auth.login.423');
      if (status === 400) {
        if (details.otp) {
          loginError = fm(18001);
        } else {
          loginError = remainingLoginAttempts
            ? fm('auth.login.400.attempts.remaining', undefined, { remainingLoginAttempts })
            : fm('auth.login.400');
        }
      }
      dispatch(setSnackbarError(loginError));
      return dispatch(setAuthFields({ errorMessage: loginError }));
    }
    dispatch(setSnackbarError('snackbar.auth.signIn.error'));
  }
};

export const requestSignInOtp = (email) => async (dispatch) => {
  try {
    await axios.post('/api/v4/otp', { email });
    dispatch(setSnackbarSuccess('otp_sent_to_mail'));
  } catch (err) {
    if (get('response.data.errors[0].details.code')(err)) {
      const errorCode = get('response.data.errors[0].details.code')(err);
      return dispatch(setAuthFields({ errorMessage: fm(errorCode) }));
    }
    dispatch(setSnackbarError('snackbar.auth.signIn.error'));
  }
};

// sign up
export const userSignUp = (details, history) => async (dispatch) => {
  try {
    dispatch(setAuthFields({ fsip: true }));
    const timestamp = btoa(new Date().toString()).replace('=', '');
    const requestBody = deepTrim(getFormattedUserDataForRegister(details));
    const singupFingerprint = btoa(`${timestamp}-${requestBody.user.email}`);
    const { data } = await axios.post(
      '/api/v3/auth/signup',
      requestBody,
      { headers: { 'X-Timestamp': timestamp, 'X-Session-token': singupFingerprint } },
    );
    if (data.success) {
      dispatch(setSnackbarSuccess('snackbar.auth.email.sent'));
      sessionStorage.setItem('verification_email', details.email);
      setTimeout(() => {
        history.push(appRoutes.emailVerification);
        dispatch(setAuthFields({ fsip: false, signupFormErrors: {} }));
      }, 1000);
    } else {
      dispatch({ payload: 'Network Error', type: FETCH_ERROR });
    }
  } catch (error) {
    batch(() => {
      if (!isEmpty(get('response.data.errors[0].details')(error))) {
        const fieldName = Object.keys(get('response.data.errors[0].details')(error))[0];
        const code = get(`response.data.errors[0].details.${fieldName}[0].code`)(error);
        dispatch(setAuthFields({ signupFormErrors: { [fieldName]: fm(code) } }));
      } else {
        dispatch(setSnackbarError('snackbar.auth.singup.error'));
      }
      dispatch(setAuthFields({ fsip: false }));
    });
  }
};

export const requestResetPassword = (email, history) => async (dispatch) => {
  try {
    dispatch(setAuthFields({ fsip: true }));
    await axios.post('/users/password', { user: { email } });
    dispatch(setSnackbarSuccess('otp_sent_to_mail'));
    history.push(appRoutes.passwordReset);
    sessionStorage.setItem('reset_password_email', email);
    dispatch(setAuthFields({ fsip: false }));
  } catch (err) {
    // 18001 invalid otp
    const errorCode = get('response.data.errors[0].details.code')(err);
    const nextAuthFields = { fsip: false };
    if (errorCode === '1010') {
      dispatch(setAuthFields({ resetPasswordError: fm(errorCode) }));
    }
    if (errorCode === '1004') {
      dispatch(setSnackbarError('snackbar.auth.resetPasswordEmail.error'));
    }
    dispatch(setAuthFields(nextAuthFields));
  }
};

export const requestChangePassword = (data, history) => async (dispatch, getState) => {
  const { auth: { loginAfterSetPasswordByOtp, loginAfterSetPasswordByDefaultPassword } } = getState();
  try {
    dispatch(setAuthFields({ fsip: true }));
    const otp = sessionStorage.getItem('reset_password_otp');
    const email = sessionStorage.getItem('reset_password_email');
    const body = deepTrim({
      email,
      otp,
      password: data.password,
      password_confirmation: data.confirmPassword,
    });
    await axios.put('/users/password', { user: body });
    dispatch(setSnackbarSuccess('snackbar.auth.passwordChanged'));
    dispatch(setAuthFields({ fsip: false }));
    if (loginAfterSetPasswordByOtp || loginAfterSetPasswordByDefaultPassword) {
      dispatch(userSignIn({ email, password: data.password }, history));
    } else {
      history.push('/login');
    }
    sessionStorage.removeItem('reset_password_otp');
    sessionStorage.removeItem('reset_password_email');
  } catch (err) {
    // 18001 invalid otp
    const errorCode = get('response.data.errors[0].details.code')(err);
    const nextAuthFields = { fsip: false };
    if (errorCode === '18001') {
      dispatch(setSnackbarError(get('response.data.errors[0].details.code')(err)));
      nextAuthFields.resetPasswordError = fm(errorCode);
    }
    dispatch(setAuthFields(nextAuthFields));
  }
};

export const requestUpdatePasswordByDefaultPassword = (data, history) => async (dispatch, getState) => {
  const { auth: { loginAfterSetPasswordByOtp, loginAfterSetPasswordByDefaultPassword } } = getState();
  try {
    const email = sessionStorage.getItem('reset_password_email');
    dispatch(setAuthFields({ fsip: true }));
    const body = {
      user: {
        email,
        password: data.password,
        password_confirmation: data.confirmPassword,
        old_password: data.currentPassword,
      },
    };
    await axios.put('/users/password/update_by_password', body);
    dispatch(setSnackbarSuccess('snackbar.auth.passwordChanged'));
    dispatch(setAuthFields({ fsip: false }));
    if (loginAfterSetPasswordByOtp || loginAfterSetPasswordByDefaultPassword) {
      dispatch(userSignIn({ email, password: data.password }, history));
    } else {
      history.push('/login');
    }
    sessionStorage.removeItem('reset_password_email');
  } catch (err) {
    dispatch(setAuthFields({ fsip: false }));
    dispatch(setSnackbarError(get('response.data.errors[0].details.code')(err)));
  }
};

export const requestCompanyToken = (company, history, openedFromModal) => async (dispatch) => {
  try {
    dispatch(setAuthFields({ fsip: true }));
    const response = await userAxios.get(`/api/v3/company/${company.id}/select`);
    storeAxios.defaults.headers.Authorization = `Bearer ${response.data.data.token}`;
    axios.defaults.headers.Authorization = `Bearer ${response.data.data.token}`;
    axiosNext.defaults.headers.Authorization = `Bearer ${response.data.data.token}`;
    localStorage.setItem('role', company.userRole);
    localStorage.setItem('token', response.data.data.token);
    dispatch(resetInitialFilters());
    dispatch(setAuthFields({
      role: company.userRole,
      token: response.data.data.token,
    }));
    history.replace('/');
    if (openedFromModal) {
      window.location.reload();
    }
    if (company.userRole !== userRoles.superAdmin) dispatch(logUserLogin());
    dispatch(setAuthFields({ fsip: false }));
  } catch (err) {
    dispatch(setAuthFields({ fsip: false }));
    dispatch(setSnackbarError('snackbar.auth.companiesError.error'));
  }
};

export const requestUserCompanies = (history, countOnly) => async (dispatch) => {
  try {
    dispatch(setAuthFields({ fsip: true }));
    const response = await userAxios.get('/api/v3/company');
    const userCompaniesData = response.data.data.companies;
    if (userCompaniesData.length === 0) {
      history.push('/contract/deactivated');
    }
    if (!countOnly && userCompaniesData.length === 1) {
      dispatch(requestCompanyToken(userCompaniesData[0], history));
    }
    dispatch(setAuthFields({ userCompanies: userCompaniesData }));
    dispatch(setAuthFields({ fsip: false }));
  } catch (err) {
    dispatch(setAuthFields({ fsip: false }));
    dispatch(setSnackbarError('snackbar.auth.companiesError.error'));
  }
};
