import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useDispatch } from 'react-redux';
import { setAuthFields, setSnackbarError } from 'appRedux/actions';
import { requestSignInWithProvider } from 'appRedux/thunks/auth/thirdPartyAuth';
import { thirdPartyProviders } from 'utils/enum';
import { useHistory } from 'react-router-dom';
import { fm } from 'utils/string';

const LoginWithApple = ({ ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccess = (data) => {
    const { authorization } = data;
    dispatch(setAuthFields({ loginType: thirdPartyProviders.apple }));
    dispatch(requestSignInWithProvider(authorization.id_token, thirdPartyProviders.apple, history));
  };

  const authOptions = {
    clientId: 'com.bokningoschema.web',
    scope: 'email name',
    redirectURI: 'https://app3.bokningoschema.se/',
    nonce: 'nonce',
    usePopup: true,
  };

  const handleFailure = () => {
    dispatch(setSnackbarError('snackbar_apple_account_fetch_failed'));
  };

  return (
    <AppleSignin
      authOptions={authOptions}
      uiType="light"
      style={{ width: '400px' }}
      onSuccess={handleSuccess}
      onError={handleFailure}
      buttonExtraChildren={fm('sign_in_with_apple')}
      {...rest}
    />
  );
};

export default LoginWithApple;
