import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { get, isArray } from 'utils/lodash';
import TablePagination from './TablePagination';
import { TableRow, SubtableHead, SubtableBody } from './styledComponents';
import { OrderOptions } from './OrderOptions';

const avoidClickElements = ['checkbox', 'button', 'svg', 'path', 'input'];
export default function Subtable(props) {
  const {
    row,
    subtableDataAccessor,
    subtableColumns,
    onSubtableRowClick,
    showSubtablePagination,
    getSubtablePagination,
    onChangeSubtablePagination,
    subtableFilters,
    setSubtableFilters,
  } = props;

  const subtableData = subtableDataAccessor(row);
  const subtablePagination = getSubtablePagination(row);

  const subtableDataKeys = React.useMemo(
    () => (isArray(subtableData) ? subtableData.map((_, idx) => idx * Math.random()) : []),
    [subtableData],
  );

  const handleChangePage = (event, nextPage) => {
    onChangeSubtablePagination(row, { ...subtablePagination, ...subtableFilters, page: nextPage + 1 });
  };

  const onRowsPerPageChange = (event) => {
    const { value } = event.target;
    onChangeSubtablePagination(row, { ...subtablePagination, ...subtableFilters, perPage: Number(value), page: 1 });
  };

  const handleRowClick = (subrecord) => {
    if (!onSubtableRowClick) return undefined;
    return (event) => {
      if (avoidClickElements.includes(event.target.tagName?.toLowerCase())) return;
      const clickableElement = event.target.className.includes('no-bubbling');
      if (['checkbox'].includes(event.target.type) || clickableElement || !onSubtableRowClick) return;
      onSubtableRowClick(subrecord, row);
    };
  };

  const getCellContent = (column, subrecord) => {
    if (typeof column.accessor === 'function') {
      return column.accessor(subrecord, row, column);
    }
    return get(column.accessor || column.id)(subrecord);
  };

  const createSortHandler = (property) => () => {
    const order = (property === subtableFilters.orderBy && subtableFilters.order === 'asc') ? 'desc' : 'asc';
    setSubtableFilters((prevFilters) => ({ ...prevFilters, orderBy: property, order }));
    props.onExpandRow(true, row, subtableFilters);
  };

  const handleChangeOrderValue = (column) => (orderValue) => {
    setSubtableFilters((prevFilters) => ({ ...prevFilters, orderValue, orderBy: column.id }));
    props.onExpandRow(true, row, subtableFilters);
  };

  return (
    <>
      <Table size="small" aria-label="purchases">
        <SubtableHead>
          <TableRow>
            {subtableColumns.map((column) => (
              <TableCell key={column.id}>
                {column.orderable ? (
                  <TableSortLabel
                    active={subtableFilters.orderBy === column.id}
                    direction={subtableFilters.orderBy === column.id ? subtableFilters.order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
                {column.orderOptions && (
                  <OrderOptions
                    options={column.orderOptions}
                    onChangeOrderValue={handleChangeOrderValue(column)}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </SubtableHead>
        <SubtableBody>
          {subtableData?.map((subrecord, idx) => (
            <TableRow key={subtableDataKeys[idx]} onClick={handleRowClick(subrecord)}>
              {subtableColumns.map((subtableColumn) => (
                <TableCell component="th" scope="row">
                  {getCellContent(subtableColumn, subrecord)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </SubtableBody>
      </Table>
      {showSubtablePagination && (
        <Box display="flex" justifyContent="end" backgroundColor="#f5f5f5">
          <TablePagination
            count={subtablePagination.totalRecords}
            page={subtablePagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPage={subtablePagination.perPage}
          />
        </Box>
      )}
    </>
  );
}

Subtable.propTypes = {
  subtableColumns: PropTypes.arrayOf(PropTypes.shape({})),
  subtableDataAccessor: PropTypes.func,
  setSubtableFilters: PropTypes.func.isRequired,
  onExpandRow: PropTypes.func.isRequired,
  onChangeSubtablePagination: PropTypes.func,
  onSubtableRowClick: PropTypes.func,
  showSubtablePagination: PropTypes.bool,
  row: PropTypes.shape({}).isRequired,
  subtableFilters: PropTypes.shape({
    orderBy: PropTypes.string,
    order: PropTypes.string,
    orderValue: PropTypes.string,
  }),
  getSubtablePagination: PropTypes.func,
};

Subtable.defaultProps = {
  getSubtablePagination: () => {},
};
