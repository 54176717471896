import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { fm } from 'utils/string';
import { proposalPolicyTypes, proposalRequestStatusTypes } from 'utils/enum';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  DialogActions,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Box,
} from 'components/library';

export default function DeclineOfferDialog (props) {
  const { open, setOpen, proposalPolicies, requestUpdateProposalFromCustomer } = props;
  const declineReasonPolicy = proposalPolicies.find((policy) => policy.rule === proposalPolicyTypes.declineReason);
  let reasons = [];

  const handleSubmit = (formikValues) => {
    setOpen(false);
    const declineReason = formikValues.declineReason === 'other'
      ? formikValues.customDeclineReason : formikValues.declineReason;
    requestUpdateProposalFromCustomer({ status: proposalRequestStatusTypes.cancelled, decline_reason: declineReason });
  };

  const formik = useFormik({
    initialValues: { declineReason: '', customDeclineReason: '' },
    validationSchema: Yup.object().shape({
      declineReason: Yup.string().required(fm('decline_reason_required')),
      customDeclineReason: Yup.string()
        .when('declineReason', {
          is: 'other',
          then: Yup.string().required(fm('custom_decline_reason_required')),
        }),
    }),
    onSubmit: handleSubmit,
  });

  try {
    reasons = JSON.parse(declineReasonPolicy.description);
  } catch (error) {
    console.log(error);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {fm('decline_proposal')}
      </DialogTitle>
      <DialogContent>
        <Typography>{fm('proposal_deny_confirm_msg')}</Typography>
        <RadioGroup
          value={formik.values.declineReason}
          onChange={(e) => formik.setFieldValue('declineReason', e.target.value)}
        >
          {reasons.map((reason) => (
            <FormControlLabel
              key={reason}
              value={reason}
              control={<Radio color="primary" />}
              label={reason}
            />
          ))}
          <FormControlLabel
            value="other"
            control={<Radio color="primary" />}
            label={fm('other')}
          />
          {formik.errors.declineReason && (
          <Typography color="error">{formik.errors.declineReason}</Typography>
          )}
          {formik.values.declineReason === 'other' && (
          <Box display="flex" flexDirection="column">
            <TextField
              required
              fullWidth
              multiline
              rows={3}
              margin="dense"
              variant="outlined"
              placeholder={fm('enter_decline_reason')}
              value={formik.values.customDeclineReason}
              onChange={(e) => formik.setFieldValue('customDeclineReason', e.target.value)}
              error={formik.touched.customDeclineReason && formik.errors.customDeclineReason}
              helperText={formik.errors.customDeclineReason}
              inputProps={{ maxLength: 255 }}
            />
            <Typography variant="caption" color="text.secondary" align="right">
              {formik.values.customDeclineReason?.length} / 255
            </Typography>
          </Box>
          )}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="inherit" onClick={handleClose}>
          {fm('close')}
        </Button>
        <Button onClick={formik.handleSubmit}>
          {fm('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeclineOfferDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  proposalPolicies: PropTypes.arrayOf(PropTypes.shape({})),
  requestUpdateProposalFromCustomer: PropTypes.func.isRequired,
};
