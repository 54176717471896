import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { get } from 'utils/lodash';
import { TableCell, TableRow } from './styledComponents';
import Subtable from './Subtable';

const avoidClickElements = ['checkbox', 'button', 'svg', 'path', 'input'];

export default function Row(props) {
  const [open, setOpen] = React.useState(false);
  const [subtableFilters, setSubtableFilters] = React.useState({});
  const {
    row,
    onRowClick,
    onSubtableRowClick,
    columns,
    subtableDataAccessor,
    subtableColumns,
    getSubtablePagination,
    showSubtablePagination,
    onChangeSubtablePagination,
  } = props;

  const handleExpandRow = (event) => {
    event.stopPropagation();
    const nextopenState = !open;
    setOpen(nextopenState);
    props.onExpandRow(nextopenState, row, subtableFilters);
  };

  useEffect(() => {
    if (open) setOpen(false);
  }, [row]);

  const handleRowClick = () => {
    if (!onRowClick) return undefined;
    return (event) => {
      const className = get('target.className')(event);
      const clickableElement = className?.includes
        ? ['no-bubbling', 'MuiBackdrop-root'].some((d) => className.includes(d))
        : false;
      if (avoidClickElements.includes(event.target.tagName?.toLowerCase()) || clickableElement) return;
      if (onRowClick) onRowClick(row, event);
    };
  };

  const getCellContent = (column) => {
    if (typeof column.accessor === 'function') {
      return column.accessor(row, column);
    }
    return get(column.accessor || column.id)(row);
  };

  return (
    <>
      <TableRow onClick={handleRowClick()}>
        {subtableColumns && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleExpandRow}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell padding={column.cellPading} key={column.id}>
            {getCellContent(column)}
          </TableCell>
        ))}
      </TableRow>
      {subtableColumns && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Subtable
                  row={row}
                  subtableFilters={subtableFilters}
                  setSubtableFilters={setSubtableFilters}
                  subtableColumns={subtableColumns}
                  subtableDataAccessor={subtableDataAccessor}
                  onSubtableRowClick={onSubtableRowClick}
                  getSubtablePagination={getSubtablePagination}
                  showSubtablePagination={showSubtablePagination}
                  onChangeSubtablePagination={onChangeSubtablePagination}
                  onExpandRow={props.onExpandRow}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

Row.propTypes = {
  subtableDataAccessor: PropTypes.func,
  onChangeSubtablePagination: PropTypes.func,
  onRowClick: PropTypes.func,
  onSubtableRowClick: PropTypes.func,
  onExpandRow: PropTypes.func,
  getSubtablePagination: PropTypes.func,
  setSubtableFilters: PropTypes.func,
  showSubtablePagination: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  subtableFilters: PropTypes.shape({}),
  subtableColumns: PropTypes.arrayOf(PropTypes.shape({})),
  row: PropTypes.shape({}).isRequired,
};
