import { setSnackbarSuccess } from 'appRedux/actions';
import { getFormattedProposalData } from 'appRedux/owner/proposals/selector';
import { useComponentDidMount } from 'hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import configuredAxios from 'utils/axios';
import { proposalRequestStatusTypes } from 'utils/enum';

export default function usePublicProposal () {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [proposal, setProposal] = useState({});
  const [documents, setDocuments] = useState([]);
  const [company, setCompany] = useState([]);

  const requestGetProposalForCustomer = async (proposalToken) => {
    const params = { token: proposalToken };
    const response = await configuredAxios.get('/api/v3/proposals/get_proposal', { params });
    setProposal(getFormattedProposalData(response.data.data.proposal));
    return setDocuments(response.data.data.document);
  };

  const requestGetProposalPoliciesAndCompanyDetails = async (proposalToken) => {
    const params = { token: proposalToken };
    const response = await configuredAxios.get('/api/v3/proposals/policies_and_company_logo', { params });
    return setCompany(response.data.data);
  };

  const requestUpdateProposalFromCustomer = async (data) => {
    const url = window.location.href;
    const params = { token: url.split('/').pop() };
    const response = await configuredAxios.put('/api/v3/proposals/handle_proposal_response', data, { params });
    dispatch(setSnackbarSuccess(
      data.status === proposalRequestStatusTypes.signed
        ? 'proposal_accepted'
        : 'proposal_deny',
    ));
    await requestGetProposalForCustomer(token);
    return response.data.data;
  };

  useComponentDidMount(async () => {
    const url = window.location.href;
    const proposalToken = url.split('/').pop();
    if (proposalToken) {
      try {
        await requestGetProposalForCustomer(proposalToken);
        await requestGetProposalPoliciesAndCompanyDetails(proposalToken);
        setToken(proposalToken);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
    }
  }, []);

  return { loading, proposal, documents, error, company, requestUpdateProposalFromCustomer };
}
