import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { fm } from 'utils/string';
import {
  getFormattedQueryParamsForProjectItems,
  getFormattedProjectItemsData,
  getFormattedItemProjectsData,
  getFormattedUpdateItemsRequestBody,
} from './selector';

export const requestProjectItems = createAsyncThunk(
  'projectItems/fetchProjectItems',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedQueryParamsForProjectItems(filters);
      const response = await extra.axios.get('/api/v3/project_items', { params });
      const { records, ...pagination } = getFormattedProjectItemsData(response.data.data);
      dispatch(setFilters({ ...pagination, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_items_get_failed'));
      return { records: [] };
    }
  },
);

export const requestItemProjects = createAsyncThunk(
  'projectItems/fetchItemProjects',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { projectItems } = getState();
      const { filters, record } = data;
      const params = { ...getFormattedQueryParamsForProjectItems(filters), title: record.title };
      const response = await extra.axios.get('/api/v3/project_items/projects', { params });
      const { records, ...paginationFields } = getFormattedItemProjectsData(response.data.data);
      const itemsTitleProjectsMap = {
        ...projectItems.itemsTitleProjectsMap,
        [record.title]: records,
      };
      const projectItemsSubTablePaginationMap = {
        ...projectItems.projectItemsSubTablePaginationMap,
        [record.title]: paginationFields,
      };
      return { itemsTitleProjectsMap, projectItemsSubTablePaginationMap };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_items_projects_get_failed'));
    }
  },
);

export const requestUpdateProjectItems = createAsyncThunk(
  'projectItems/updateProjectItems',
  async (data, { extra, dispatch }) => {
    try {
      const body = getFormattedUpdateItemsRequestBody(data);
      await extra.axios.put('/api/v3/project_items/bulk_update ', body);
      dispatch(setSnackbarSuccess(fm('snackbar_project_items_updated')));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_items_update_failed'));
    }
  },
);
